import React, { useEffect, useRef, useState } from "react";
import Page from "../../../../shared/Page";
import CustomCard from "../../../../shared/CustomCard";
import { CustomForm } from "../../../../shared/AllInputs";
import {
  CustomViewChips,
  CustomViewInput,
} from "../../../../shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../../services/auth";
import { Messages } from "primereact/messages";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EditIcon from "../../../../assets/images/icons/editicon.png";
import { getOfficeInfoAction } from "../../../../store/actions/officeActions";
import InviteEmployees from "../../../../shared/Components/InviteEmployees";
import { Toast } from "primereact/toast";
import CustomBankAccountTable from "../../../Tables/CustomBankAccountTable"
export default function ViewOffice() {
  const [data, setData] = useState();
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const msgs = useRef(null);
  const { profile } = useSelector((state) => state.user);
  const [allBankAccounts, setAllBankAccounts] = useState([])

  useEffect(() => {
    let info = "";
    const userInfo = getUserInfo();
    profile?.firstName ? (info = profile) : (info = userInfo);
    if (info?.company) {
      getOfficeInfo(info?.company);
    }
  }, [profile]);

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() => navigate(`/administrator/office/edit/${params?.id}`)}
        ></img>
      </div>
    );
  };

  const getOfficeInfo = (company) => {
    dispatch(
      getOfficeInfoAction(company, params?.id, (res) => {
        if (res?.success) {
          let results = res?.data?.results;
          let employees = results?.employees?.map((emp) => {
            return {
              name: emp?.employee?.name,
              role: emp?.employee?.role,
              position: emp?.position,
            };
          });
          let info = {
            officeId: results?.office_id,
            officeName: results?.name,
            officeNickName: results?.nick_name,
            officeEmail: results?.office_email,
            phoneNumber: results?.phone_number,
            unit: results?.office_unit?.name,
            officePurpose: results?.purpose,
            officeRepresentative: results?.office_rep?.name,

            address: results?.address,
            street: results?.street,
            zipCode: results?.zipcode,
            city: results?.city,
            cityDivision: results?.city_division,
            country: results?.country,

            costCenter: results?.cost_center,
            banks: results?.bank_accounts,
            employees: employees,
            invitedEmployees: results?.invite_employee,
          };
          setData(info);
          setAllBankAccounts(results?.bank_accounts)
        } else {
          msgs.current.show({
            severity: "error",
            detail: res?.error,
          });
        }
      })
    );
  };

  const actions = () => {
    return <i className="pi pi-ellipsis-v text-gray-400"></i>;
  };

  return (
    <Page title="View Details" description={`Let’s view your Office Details`}>
      <CustomCard>
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />
        <CustomForm title="General" header={header()}>
          <CustomViewInput name="officeId" data={data} label={"Office ID"}/>
          <CustomViewInput name="officeName" data={data} />
          <CustomViewInput name="officeNickName" data={data} />
          <CustomViewInput name="officeEmail" data={data} />
          <CustomViewInput name="phoneNumber" data={data} />
          <CustomViewInput name="unit" data={data} />
          <CustomViewInput name="officePurpose" data={data} />
          <CustomViewInput name="officeRepresentative" data={data} />
        </CustomForm>
        <CustomForm title="Address">
          <CustomViewInput name="address" data={data} />
          <CustomViewInput name="street" data={data} />
          <CustomViewInput name="zipCode" data={data} />
          <CustomViewInput name="city" data={data} />
          <CustomViewInput name="cityDivision" data={data} />
          <CustomViewInput name="country" data={data} />
        </CustomForm>
        <CustomForm title="Financial Details">
          <CustomViewInput name="costCenter" data={data} />
        </CustomForm>
        <CustomForm title="Bank Accounts">
          <DataTable value={data?.banks} tableStyle={{ minWidth: "50rem" }}>
            <Column
              headerClassName="custom-header"
              field="purpose"
              header="purpose"
            ></Column>
            <Column
              headerClassName="custom-header"
              field="owner_name"
              header="Owner Name"
            ></Column>
            <Column
              headerClassName="custom-header"
              field="iban"
              header="IBAN"
            ></Column>
            <Column
              headerClassName="custom-header"
              field="bic"
              header="BIC"
            ></Column>
            <Column
              headerClassName="custom-header"
              body={actions}
              style={{ width: "80px" }}
              header="Action"
            ></Column>
          </DataTable>
          {/* <CustomBankAccountTable allBankAccounts={allBankAccounts} setAllBankAccounts={setAllBankAccounts}/> */}
        </CustomForm>
        <CustomForm title="Existing Office Employee">
          <DataTable value={data?.employees} tableStyle={{ minWidth: "50rem" }}>
            <Column
              headerClassName="custom-header"
              field="role"
              header="Select Role"
            ></Column>
            <Column
              headerClassName="custom-header"
              field="position"
              header="Select Position"
            ></Column>
            <Column
              headerClassName="custom-header"
              field="name"
              header="Select Employee"
            ></Column>
            <Column
              headerClassName="custom-header"
              body={actions}
              style={{ width: "80px" }}
              header="Action"
            ></Column>
          </DataTable>
        </CustomForm>
        <InviteEmployees
          type={"ViewProject"}
          title="Invite New Office Employees"
          allInvites={data?.invitedEmployees}
          disabled
        />
      </CustomCard>
    </Page>
  );
}
