import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { firstLetterToUppercase } from "../../utils/regex";
import { showToastAction } from "../slices/commonSlice";
import { setPropertySlice } from "../slices/propertySlice";

const addPropertyAction = (company, data, buildingId, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        property_id: data?.propertyId,
        address: data?.address,
        zipcode: data?.zipCode,
        city: data?.city,
        house_number: data?.houseNo,
        street: data?.street,
        city_division: data?.cityDivision,
        country: data?.country,
        building: buildingId
    };

    const res = await api(
        "post",
        endPoints?.PROPERTY + `${company}/`,
        payload
    );
    // onRes(res)
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        dispatch(setPropertySlice(res?.data?.results))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false)
};

const searchPropertyIdAction = (payload, onRes) => async () => {
    const res = await api(
        "post",
        endPoints?.PROPERTY_SUGGESTIONS,
        payload
    )
    onRes(res)
}

const getPropertyDataAction = (company, property, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.PROPERTY + `${company}/${property}/`,
    );
    onRes(res)
}

const getPropertiesListAction = (company, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.PROPERTY + `${company}/`,
    );
    onRes(res)
}

const propertiesListActionModal = (company, rows, page, search, propertyId, contractId, address, status, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.PROPERTY + `${company}/?limit=${rows}&page=${page}&search=${search}&status=${status || ""}&address=${address || ""}&property_id=${propertyId || ""}&contractId=${contractId || ""}`,
    );
    // onRes(res);
    if(res?.success){
        let options = res?.data.results.map((property) => {
            return {
              id: property?.id,
              property_id: property?.property_id,
              address: firstLetterToUppercase(property?.address),
              outStatus: property?.out_status,
              building__type: firstLetterToUppercase(
                property?.building_data?.type
              ),
              building__staircase: firstLetterToUppercase(
                property?.building_data?.staircase
              ),
              status: property?.status,
              heatingDevice: property?.building_unit?.heating_technology,
              buildingMaxFloor: property?.building_data.max_floor,
              unitType: property?.building_unit?.unit_type,
              floor: property?.building_unit?.floor,
              location: property?.building_unit?.location,
              rooms: property?.building_unit?.rooms_count,
              doors: property?.building_unit?.doors_count,
              allEenergyMeters: property?.building_unit?.energy_meters || [],
              allRooms: property?.building_unit?.rooms || [],
              landlord_name: property?.landlord_name
              // ...property,
            };
          });
          
          onRes({options: options, count: res?.data?.count})
    }
};

const propertiesListAction = (company, rows, page, search, ordering, buildingType, staircase, status, location, heatingDevice, unitType, outStatus, address, property_id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.PROPERTY + `${company}/?limit=${rows}&page=${page}&search=${search}&ordering=${ordering}&building_type=${buildingType || ""}&staircase=${staircase || ""}&status=${status || ""}&location=${location || ""}&heatingDevice=${heatingDevice || ""}&unitType=${unitType || ""}&outStatus=${outStatus || ""}&address=${address || ""}&property_id=${property_id || ""}`,
    );
    // onRes(res);
    if(res?.success){
        let options = res?.data.results.map((property) => {
            return {
              id: property?.id,
              property_id: property?.property_id,
              address: firstLetterToUppercase(property?.address),
              outStatus: property?.out_status,
              building__type: firstLetterToUppercase(
                property?.building_data?.type
              ),
              building__staircase: firstLetterToUppercase(
                property?.building_data?.staircase
              ),
              status: property?.status,
              heatingDevice: property?.building_unit?.heating_technology,
              buildingMaxFloor: property?.building_data.max_floor,
              unitType: property?.building_unit?.unit_type,
              floor: property?.building_unit?.floor,
              location: property?.building_unit?.location,
              rooms: property?.building_unit?.rooms_count,
              doors: property?.building_unit?.doors_count,
              allEenergyMeters: property?.building_unit?.energy_meters || [],
              allRooms: property?.building_unit?.rooms || []
              // ...property,
            };
          });
          
          onRes({options: options, count: res?.data?.count})
    }
};
const searchProperty = (company, id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.PROPERTY + `${company}/?property_id=${id}`,
    );
    if(res?.success){
        onRes(res?.data?.results)
    }
}

const getPorpertyInfoAction = (company, propertyId, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.PROPERTY + `${company}/${propertyId}`,
    );

    if (res?.success) {
        dispatch(setPropertySlice(res?.data?.results))
        onRes && onRes(res?.data?.results)
    }
};

const updatePropertyAction = (company, property, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        property_id: data?.propertyId,
        address: data?.address,
        zipcode: data?.zipCode,
        city: data?.city,
        house_number: data?.houseNo,
        street: data?.street,
        city_division: data?.cityDivision,
        country: data?.country,
      };
    const res = await api(
        "patch",
        endPoints?.PROPERTY + `${company}/${property}/`,
        payload
    );
    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        dispatch(setPropertySlice(res?.data?.results))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const propertyDeleteAction = (company, propertyId, setLoading, deleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.PROPERTY + `${company}/${propertyId}`,
    );
     if (res?.success) {
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        dispatch(setPropertySlice(res?.data?.results))
        onRes(res)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
    deleteModal(false)
};

export {
    addPropertyAction,
    searchPropertyIdAction,
    getPropertyDataAction,
    getPropertiesListAction,
    getPorpertyInfoAction,
    updatePropertyAction,
    propertyDeleteAction,
    propertiesListAction,
    searchProperty,
    propertiesListActionModal
}