import React, { useState } from "react";
import CustomCard from "../../../../shared/CustomCard";
import Page from "../../../../shared/Page";
import { CustomForm } from "../../../../shared/AllInputs";
import { CustomViewInput } from "../../../../shared/AllViewInputs";
import CommonViewTable from "../../../Tables/CommonViewTable";
const DamageHistory = () => {
  const [data, setData] = useState();

  const columns = [
    { name: "Reported Date", accessor: "" },
    { name: "Status", accessor: "" },
    { name: "Damage Type", accessor: "" },
    { name: "Damage Cause", accessor: "" },
  ];

  return (
    <Page
      title={`Damage History`}
      navigateLink={"/real-estate/maintenance/handyman/list"}
    >
      <CustomCard>
        <CustomForm title={`Property Details`}>
          <CustomViewInput data={data} name="propertyID" />
          <CustomViewInput data={data} name="address" />
          <CustomViewInput data={data} name="landlordName" />
          <CustomViewInput data={data} name="movingDate" />
          <CustomViewInput data={data} name="typeOfMaturity" />
          <CustomViewInput data={data} name="rent" />
          <CustomViewInput data={data} name="tenantsName" />
          <CustomViewInput data={data} name="status" />
        </CustomForm>
        <CommonViewTable columns={columns} />
      </CustomCard>
    </Page>
  );
};

export default DamageHistory;
