import { entries, notEqual, values } from "./javascript";
import formValidation from "./validations";
import constants from "../constants";
import { KEYS } from "./keys";
import { zipCodeValidation } from "./regex";

export const showFormErrors = (data, setData, ignore, errorName) => {
  let formErrors = {};
  entries(data).forEach(([key, value]) => {
    formErrors = {
      ...formErrors,
      ...formValidation(key, value, data, ignore, errorName),
    };
  });

  setData({ ...data, formErrors });
  return !values(formErrors).some((v) => notEqual(v, ""));
};

export const capitalizeCamelCase = (str) => {
  return str
    .replace(/([a-z])([A-Z])/g, '$1 $2') // Convert camelCase to space-separated
    .replace(/_/g, ' ') // Convert snake_case to spaces
    .replace(/^\w/, (c) => c.toUpperCase()) // Capitalize the first letter
    .replace(/\b\w/g, char => char.toUpperCase())
  // let words = str.split(/(?=[A-Z])/);
  // let capitalizedWords = words.map(function (word) {
  //   return word.charAt(0).toUpperCase() + word.slice(1);
  // });
  // let capitalizedString = capitalizedWords.join(" ");
  // return capitalizedString;
};

export const getImageUrl = (image) => {
  if (image.includes("http")) {
    return image;
  } else {
    return constants.baseUrl + image;
  }
};

export const storePropertyId = (propertyId) => {
  if (propertyId) {
    localStorage.setItem(KEYS?.PropertyId, propertyId)
  }
}

export const handleFileConversion = (file) => {
  return new Promise((resolve, reject) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(',')[1];
        resolve(`${file?.name};base64,${base64String}`);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    } else {
      resolve(""); // or reject with an appropriate message if needed
    }
  });
}

export const handleFormatDate = (value) => {
  if (value) {
    let date = new Date(value);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();
    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    let formattedDate = year + "-" + month + "-" + dt;
    return formattedDate
  }
};

export const handleSetAddress = (selectedAddress, data, setData, ignore = []) => {
  const { address, zipCode, city, country, cityDivision, street, lng, lat } = selectedAddress;
  let newFormErrors = { ...data.formErrors };

  if (address) {
    newFormErrors.address = '';
  }
  if (zipCode) {
    if (zipCodeValidation(zipCode)) {
      newFormErrors.zipCode = '';
    } else {
      newFormErrors.zipCode = "Please enter valid Zip Code!"
    }
  }
  if (city) {
    newFormErrors.city = '';
  }
  if (country) {
    newFormErrors.country = '';
  }
  if (cityDivision) {
    newFormErrors.cityDivision = '';
  }
  if (street) {
    newFormErrors.street = '';
  }

  setData((prev) => ({
    ...prev,
    ["address"]: address,
    ["zipCode"]: zipCode || "",
    ["city"]: city || "",
    ["country"]: country || "",
    ["cityDivision"]: cityDivision || "",
    ["street"]: street || "",
    formErrors: newFormErrors,

    ...(!ignore.includes("longitude") && { longitude: lng }),
    ...(!ignore.includes("latitude") && { latitude: lat })
  }));

}

export const checkIsValueGreater = (value, maxValue) => {
  const numericValue = Number(value);
  const numericMaxSize = Number(maxValue);

  if (numericValue > numericMaxSize) {
    return true
  } else {
    return false
  }
}

export const convertAmountInGerman = (value) => {
  let val = Number(value)
  if (val) {
    return val.toLocaleString("de-DE", {
      // style: "currency",
      // currency: "EUR",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  } else {
    return "-----"
  }

}

export const getFileExtension = (filePath) => {
  if(filePath){
    return filePath?.split('.').pop().split(/\#|\?/)[0];
  }
}

export const getFileName = (filePath) => {
  if(filePath){
    return filePath?.split('/').pop().split('.')[0];
  }
}