import React, { useEffect, useMemo, useRef, useState } from "react";
import CustomCard from "../../../shared/CustomCard";
import { CustomInput, CustomSearch } from "../../../shared/AllInputs";
import CustomButton from "../../../shared/CustomButton";
import { useLocation, useNavigate } from "react-router-dom";
import CustomTableContainer from "../../Tables/CustomTableContainer";
import CustomOverlay from "../../../shared/CustomOverlay";
import CustomModal from "../../../shared/Modals/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import { setUserProfile } from "../../../store/slices/userSlice";
import { getUserInfo } from "../../../services/auth";
import { BuildingTypeOptions } from "../../../shared/AllDropDownOptions";
import { Dropdown } from "primereact/dropdown";
import manageColICon from "../../../assets/images/icons/manage-col-icon.png";
import { debounce } from "lodash";
import {
  deleteBuildingAction,
  getBuildingListAction,
} from "../../../store/actions/buildingActions";
import { setBuildingSlice } from "../../../store/slices/buildingSlices";
import { Tooltip } from "primereact/tooltip";
import { truncateText } from "../../../utils/regex";

const BuildingList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const globalFilters = ["name", "type", "address"];
  const [filters, setFilters] = useState({
    name: { value: "" },
    type: { value: "" },
    address: { value: "" },
  });
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const page = query.get("page") || 1;
  const rows = query.get("rows") || 10;
  const { profile } = useSelector((state) => state.user);
  const [buildingList, setBuildingList] = useState([]);
  const { building } = useSelector((state) => state?.building);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [count, setCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [idToDelete, setIdToDelete] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  // const [sortField, setSortField] = useState({ field: "", order: "" });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!profile?.firstName) {
      let info = getUserInfo();
      dispatch(setUserProfile(info));
    }
  }, []);

  useMemo(() => {
    setFirst(page * rows - rows);
  }, []);

  const header = () => {
    return (
      <div className="w-12 md:w-7 flex justify-content-end flex-wrap">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value);
              setFirst(0);
              debouncedOnChange.current(
                profile?.company,
                10,
                1,
                value,
                filters?.name?.value,
                filters?.type?.value,
                filters?.address?.value
              );
            }}
            isSearch={isSearch}
            placeholder="Search Building"
          />
        </div>
        <CustomButton
          size="small"
          icon="pi pi-plus"
          label="Add Building"
          onClick={() => navigate("/real-estate/property/building/add")}
        />
      </div>
    );
  };

  const BuildingNameFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="buildingName"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Building Name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const BuildingTypeFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={BuildingTypeOptions}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Building Type"
          className="p-column-filter"
        />
      </div>
    );
  };

  const AddressFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="address"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Address"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const addressBodyTemplate = (options) => {
    return (
      <div>
        <Tooltip
          className="tooltip"
          target={`.tooltip-${options?.id}`}
          content={options?.address}
        />
        <span
          className={`tooltip-${options?.id}`}
          data-pr-classname="tooltip"
          style={{ cursor: "pointer" }}
        >
          <span
            style={{
              color: options?.id === building?.id ? "white" : null,
            }}
          >
            {truncateText(options?.address, 20)}
          </span>
        </span>
      </div>
    );
  };

  const columns = useMemo(
    () => [
      {
        name: "Building Name",
        accessor: "name",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        filterElement: (option) => BuildingNameFilter(option),
      },
      {
        name: "Address",
        accessor: "address",
        value: true,
        filter: true,
        body: (options) => addressBodyTemplate(options),
        filterElement: (options) => AddressFilter(options),
      },
      {
        name: "Building Type",
        accessor: "type",
        value: true,
        filter: true,
        filterElement: (options) => BuildingTypeFilter(options),
      },
      {
        name: "Building Max Floor",
        accessor: "max_floor",
        sortable: false,
        value: true,
      },
      {
        name: "Staircase Access",
        accessor: "staircase",
        sortable: false,
        value: true,
      },
      {
        name: "Size",
        accessor: "size",
        sortable: false,
        value: true,
      },
    ],
    []
  );

  const actions = (d) => {
    return (
      <CustomOverlay>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => {
              navigate(`/real-estate/property/building/view/${d.id}`);
              dispatch(setBuildingSlice(d));
            }}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          <li className="flex gap-2 text-xs font-medium mb-3">
            <i
              className="pi pi-trash cursor-pointer text-dark"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}
            ></i>{" "}
            Delete
          </li>
        </ul>
      </CustomOverlay>
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected building from the list?
        This action cannot be undone.
      </span>
    );
  };

  const handleBuildingDelete = () => {
    dispatch(
      deleteBuildingAction(
        profile?.company,
        idToDelete,
        setLoading,
        setDeleteModal,
        dispatch,
        (res) => {
          getBuildingList(profile?.company, rows, page, search);
        }
      )
    );
  };

  const ActionTemplate = () => {
    return (
      <>
        <p
          className="flex gap-2 align-items-center"
          // onClick={(e) => openAction.current.toggle(e)}
        >
          {/* <img src={manageColICon} style={{ width: "27px" }} />
          <i className="pi pi-chevron-down"></i> */}
          Action
        </p>
      </>
    );
  };

  const getBuildingList = (
    company,
    rows,
    page,
    search,
    // order,
    name,
    type,
    address
  ) => {
    dispatch(
      getBuildingListAction(
        company,
        rows,
        page,
        search,
        // order,
        name,
        type,
        address,
        (res) => {
          setBuildingList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
          navigate(
            `/real-estate/property/building/list?page=${page}&rows=${rows}`
          );
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    // order,
    name,
    type,
    address
  ) => {
    setIsSearch(true);
    getBuildingList(
      company,
      rows,
      page,
      search,
      // order,
      name,
      type,
      address
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      getBuildingList(
        profile?.company,
        rows,
        page,
        "",
        filters?.name?.value,
        filters?.type?.value,
        filters?.address?.value
      );
    }
  }, [
    profile?.company,
    rows,
    page,
    first,
    filters?.name?.value,
    filters?.type?.value,
    filters?.address?.value,
  ]);

  const onPageChange = (e) => {
    navigate(
      `/real-estate/property/building/list?page=${e.page + 1}&rows=${e.rows}`
    );
    setFirst(e.first);
  };

  return (
    <div>
      <CustomCard title="Building List" header={header()}>
        <CustomTableContainer
          list={buildingList}
          columns={columns}
          actions={actions}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={ActionTemplate}
          activeRow={building?.id}
          slice={setBuildingSlice}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
        />
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleBuildingDelete}
        loading={loading}
      />
    </div>
  );
};

export default BuildingList;
