import React, { useEffect, useRef, useState } from "react";
import formValidation from "../../../../utils/validations";
import { showFormErrors } from "../../../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../../services/auth";
import { useNavigate, useParams } from "react-router-dom";
import { getRolesListAction } from "../../../../store/actions/rolesActions";
import { addEmployeeAction, getEmployeeInfoAction, searchEmployeeIdAction, updateEmployeeAction } from "../../../../store/actions/EmployeeActions";
import { debounce } from "lodash";

const EmployeeContainer = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const params = useParams()
    const [msgToShow, setMsgToShow] = useState({ type: "", message: "" });
    const { profile } = useSelector((state) => state.user);
    const [data, setData] = useState({
        employeeId: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        selectRole: "",
    });
    const [userProfile, setUserProfile] = useState();
    const [loading, setLoading] = useState(false);
    const [rolesList, setRolesList] = useState([]);
    const [allOffices, setAllOffices] = useState([
        { id: null, office: null, position: null, status: null },
    ]);
    const [employeeIdOptions, setEmployeeIdOptions] = useState([])

    useEffect(() => {
        let info = "";
        const userInfo = getUserInfo();
        profile?.firstName ? (info = profile) : (info = userInfo);
        setUserProfile(info);
        if (info?.company) {
            handleGetRolesList(info?.company);
        }

        if (params?.id && info?.company) {
            getEmployeeInfo(info?.company, params?.id)
        }

    }, [profile, params?.id]);

    const handleGetRolesList = (company) => {
        dispatch(
            getRolesListAction(company, (res) => {
                if (res?.success) {
                    let options = res?.data?.results.map((role) => {
                        return { name: role?.name, value: role?.id };
                    });
                    setRolesList(options);
                } else {
                    setMsgToShow({ type: "error", message: res?.message })
                }
            })
        );
    };

    const handleChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (showFormErrors(data, setData)) {
            let payload = {
                employee_id: data?.employeeId,
                first_name: data?.firstName,
                last_name: data?.lastName,
                email: data?.email,
                phone_number: data?.phoneNumber,
                role: data?.selectRole,
            };
            let payloadData = JSON.stringify(payload);
            let isAllAccSaved = allOffices.every((emp) => emp.status === "save");
            if (isAllAccSaved) {
                payload.offices = allOffices;
            }
            // else {
            //     setMsgToShow({ type: "error", message: "Please save your details first." })
            // }
            if (params?.id) {
                dispatch(
                    updateEmployeeAction(
                        userProfile?.company,
                        params?.id,
                        payloadData,
                        setLoading,
                        navigate,
                        (error) => {
                            setMsgToShow({ type: "error", message: error })
                        }
                    )
                );
            } else {
                dispatch(
                    addEmployeeAction(
                        userProfile?.company,
                        payloadData,
                        setLoading,
                        navigate,
                        (error) => {
                            setMsgToShow({ type: "error", message: error })
                        }
                    )
                );
            }

        }
    };

    const getEmployeeInfo = (company, id) => {
        dispatch(
            getEmployeeInfoAction(company, id, (res) => {
                if (res?.success) {
                    let results = res?.data?.results
                    setData({
                        id: results?.id,
                        employeeId: results?.employee_id,
                        firstName: results?.user?.first_name,
                        lastName: results?.user?.last_name,
                        email: results?.user?.email,
                        phoneNumber: results?.user?.phone_number,
                        selectRole: results?.role?.id,
                    })
                    let offices = results?.offices?.map((ofc) => {
                        return { id: ofc?.id, office: ofc?.id, position: ofc?.position, status: "save" }
                    })
                    setAllOffices(offices)
                } else {
                    setMsgToShow({ type: "error", message: res?.error })
                }
            })
        );
    };

    const handleEmployeeIdChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));
        if (value) {
            debouncedOnChange.current(value);
        }
    };

    const handleSearch = (value) => {
        let payload = {
            employee_id: value,
        };

        dispatch(
            searchEmployeeIdAction(payload, (res) => {
                if (!res?.success) {
                    const formErrors = ({ ["employeeId"]: res?.message })
                    setData((prev) => ({ ...prev, ["employeeId"]: value, formErrors }));
                    let options = res?.data?.results?.map((option) => {
                        return { name: option, value: option }
                    })
                    setEmployeeIdOptions(options)
                }
            })
        );
    };

    const debouncedOnChange = useRef(debounce(handleSearch, 1000));


    return {
        data,
        handleChange,
        rolesList,
        allOffices,
        setAllOffices,
        onSubmit,
        loading,
        msgToShow,
        employeeIdOptions,
        handleEmployeeIdChange,
    }
}

export default EmployeeContainer