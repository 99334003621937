import Dashboard from "../views/Dashboard";
import AddEmployee from "../views/Administrator/Employees/Employee/AddEmployee";
import EmployeesList from "../views/Administrator/Employees/EmployeesList";
import ViewEmployee from "../views/Administrator/Employees/Employee/ViewEmployee";
import AddOffice from "../views/Administrator/Office/OfficeForms/AddOffice";
import OfficeList from "../views/Administrator/Office/OfficeList";
import AddOfficeUnit from "../views/Administrator/OfficeUnit/OfficeUnitForms/AddOfficeUnit";
import OfficeUnitList from "../views/Administrator/OfficeUnit/OfficeUnitList";

import Reports from "../views/Reports";
import AddRole from "../views/Administrator/Roles/RolesForm/AddRoles";
import RolesList from "../views/Administrator/Roles/RolesList";
import AddTenant from "../views/Tenants/TenantsForm/AddTenant";
import TenantsList from "../views/Tenants/TenantsList";
import EditEmployee from "../views/Administrator/Employees/Employee/EditEmployee";
import ViewOfficeUnit from "../views/Administrator/OfficeUnit/OfficeUnitForms/ViewOfficeUnit";
import EditOfficeUnit from "../views/Administrator/OfficeUnit/OfficeUnitForms/EditOfficeUnit";
import ViewOffice from "../views/Administrator/Office/OfficeForms/ViewOffice";
import EditOffice from "../views/Administrator/Office/OfficeForms/EditOffice";
import ViewRole from "../views/Administrator/Roles/RolesForm/ViewRoles";
import EditRoles from "../views/Administrator/Roles/RolesForm/EditRoles";
import PropertyList from "../views/RealEstate/Properties/PropertyList";
import AddProperty from "../views/RealEstate/Properties/PropertyForms/AddProperty";
import ViewEditProperty from "../views/RealEstate/Properties/ViewPropertyForms/EditViewProperty";
import ContractList from "../views/RealEstate/Contract/ContractList";
import BuildingList from "../views/RealEstate/Building/BuildingList";
import AddContract from "../views/RealEstate/Contract/ContractForms/AddContract";
import ViewContractForm from "../views/RealEstate/Contract/ViewContractForms/ViewContractForm";
import EditContract from "../views/RealEstate/Contract/ContractForms/EditContract";
import AddBuilding from "../views/RealEstate/Building/BuildingForms/AddBuilding";
import EditBuilding from "../views/RealEstate/Building/BuildingForms/EditBuilding";
import ViewBuilding from "../views/RealEstate/Building/BuildingForms/ViewBuilding";
import LandlordList from "../views/RealEstate/Landlord/LandlordList";
import AddLandlord from "../views/RealEstate/Landlord/LandlordForms/AddLandlord";
import ViewLandlord from "../views/RealEstate/Landlord/LandlordForms/ViewLandlord";
import HandymanServiceList from "../views/Settings/HandymanServices/HandymanServiceList";
import HandymanList from "../views/RealEstate/Maintenance/Handyman/HandymanList";
import AddHandyman from "../views/RealEstate/Maintenance/Handyman/HandymanForms/AddHandyman";
import EditHandyman from "../views/RealEstate/Maintenance/Handyman/HandymanForms/EditHandyman";
import EditLandlord from "../views/RealEstate/Landlord/LandlordForms/EditLandlord";
// import ViewHandymanForms from "../views/RealEstate/Maintenance/Handyman/ViewHandymanForms/ViewHandymanForm";
import ViewHandyman from "../views/RealEstate/Maintenance/Handyman/HandymanForms/ViewHandymanForm";
// import DamageList from "../views/RealEstate/Maintenance/Damage/DamageList";
// import AddDamage from "../views/RealEstate/Maintenance/Damage/DamageForms/AddDamageReport";
// import ViewDamageForms from "../views/RealEstate/Maintenance/Damage/ViewDamageForms/ViewDamageForms";
import DamageHistory from "../views/RealEstate/Maintenance/Damage/DamageHistory";
import DamageReportList from "../views/RealEstate/Maintenance/Damage/DamageList";
// import ViewDamage from "../views/RealEstate/Maintenance/Damage/DamageForms/ViewDamageReport";
import AddDamageReport from "../views/RealEstate/Maintenance/Damage/DamageForms/AddDamageReport";
import AddDamageForm from "../views/CommonComponents/AddDamageForm";
import ViewDamageReport from "../views/RealEstate/Maintenance/Damage/DamageForms/ViewDamageReport";

export const PrivateRoutes = [

  {
    path: "/dashboard",
    name: "Dashboard",
    element: <Dashboard />,
  },
  {
    path: "/reports",
    name: "Reports",
    element: <Reports />,
  },
  {
    path: "/tenants/list",
    name: "Tenants List",
    element: <TenantsList />,
  },
  {
    path: "/tenants/add",
    name: "Add Tenant",
    element: <AddTenant />,
  },
  {
    path: "/administrator/office-unit/list",
    name: "Office Unit List",
    element: <OfficeUnitList />,
  },
  {
    path: "/administrator/office-unit/add",
    name: "Add Office Unit",
    element: <AddOfficeUnit />,
  },
  {
    path: "/administrator/office-unit/view/:id",
    name: "Add Office Unit",
    element: <ViewOfficeUnit />,
  },
  {
    path: "/administrator/office-unit/edit/:id",
    name: "Add Office Unit",
    element: <EditOfficeUnit />,
  },
  {
    path: "/administrator/office/list",
    name: "Office Unit List",
    element: <OfficeList />,
  },
  {
    path: "/administrator/office/view/:id",
    name: "Add Office Unit",
    element: <ViewOffice />,
  },
  {
    path: "/administrator/office/add",
    name: "Add Office Unit",
    element: <AddOffice />,
  },
  {
    path: "/administrator/office/edit/:id",
    name: "Add Office Unit",
    element: <EditOffice />,
  },
  {
    path: "/administrator/employees/list",
    name: "Office Unit List",
    element: <EmployeesList />,
  },
  {
    path: "/administrator/employees/add",
    name: "Add Office Unit",
    element: <AddEmployee />,
  },
  {
    path: "/administrator/employees/view/:id",
    name: "Add Office Unit",
    element: <ViewEmployee />,
  },
  {
    path: "/administrator/employees/edit/:id",
    name: "Add Office Unit",
    element: <EditEmployee />,
  },
  {
    path: "/administrator/roles/list",
    name: "Roles List",
    element: <RolesList />,
  },
  {
    path: "/administrator/roles/add",
    name: "Add Role ",
    element: <AddRole />,
  },
  {
    path: "/administrator/roles/view/:id",
    name: "View Role ",
    element: <ViewRole />,
  },
  {
    path: "/administrator/roles/edit/:id",
    name: "Edit Role ",
    element: <EditRoles />,
  },

  {
    path: "/real-estate/property/list",
    name: "Property List",
    element: <PropertyList />,
  },
  //add property
  {
    path: "/real-estate/property/add/building-structure",
    name: "Add Property",
    element: <AddProperty />,
  },
  {
    path: "/real-estate/property/add/building-unit",
    name: "Add Property",
    element: <AddProperty />,
  },
  {
    path: "/real-estate/property/add/landlord-info",
    name: "Add Property",
    element: <AddProperty />,
  },
  {
    path: "/real-estate/property/add/contractual-info",
    name: "Add Property",
    element: <AddProperty />,
  },
  {
    path: "/real-estate/property/add/rent-and-cost",
    name: "Add Property",
    element: <AddProperty />,
  },
  {
    path: "/real-estate/property/add/annual-statement",
    name: "Add Property",
    element: <AddProperty />,
  },
  //view property
  {
    path: "/real-estate/property/view/property/:id",
    name: "View Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/building-structure/:id",
    name: "View Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/building-unit/:id",
    name: "View Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/landlord-info/:id",
    name: "View Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/contractual-info/:id",
    name: "View Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/rent-and-cost/:id",
    name: "View Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/view/annual-statement/:id",
    name: "View Property",
    element: <ViewEditProperty />,
  },

  //edit property
  {
    path: "/real-estate/property/edit/property/:id",
    name: "Edit Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/building-structure/:id",
    name: "Edit Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/building-unit/:id",
    name: "Edit Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/landlord-info/:id",
    name: "Edit Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/contractual-info/:id",
    name: "Edit Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/rent-and-cost/:id",
    name: "Edit Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/edit/annual-statement/:id",
    name: "Edit Property",
    element: <ViewEditProperty />,
  },
  {
    path: "/real-estate/property/contract/list",
    name: "Contract List",
    element: <ContractList />,
  },
  {
    path: "/real-estate/property/contract/add",
    name: "Add Contract",
    element: <AddContract />,
  },
  {
    path: "/real-estate/property/contract/view/:id",
    name: "View Contract",
    element: <ViewContractForm />,
  },
  {
    path: "/real-estate/property/contract/edit/:id",
    name: "Edit Contract",
    element: <EditContract />,
  },
  {
    path: "/real-estate/property/building/list",
    name: "Contract List",
    element: <BuildingList />,
  },
  {
    path: "/real-estate/property/building/add",
    name: "Edit Contract",
    element: <AddBuilding />,
  },
  {
    path: "/real-estate/property/building/add",
    name: "Add Building",
    element: <AddBuilding />,
  },
  {
    path: "/real-estate/property/building/view/:id",
    name: "View Building",
    element: <ViewBuilding />,
  },
  {
    path: "/real-estate/property/building/edit/:id",
    name: "Edit Building",
    element: <EditBuilding />,
  },
  {
    path: "/real-estate/landlord/list",
    name: "Landlord List",
    element: <LandlordList />,
  },
  {
    path: "/real-estate/landlord/add",
    name: "Add Landlord",
    element: <AddLandlord />,
  },
  {
    path: "/real-estate/landlord/edit/:id",
    name: "Edit Landlord",
    element: <EditLandlord />,
  },
  {
    path: "/real-estate/landlord/view/:id",
    name: "View Landlord",
    element: <ViewLandlord />,
  },
  {
    path: "/settings/handyman-services",
    name: "Handyman Services",
    element: <HandymanServiceList />,
  },
  {
    path: "/real-estate/maintenance/handyman/list",
    name: "Handyman List",
    element: <HandymanList />,
  },
  {
    path: "/real-estate/maintenance/handyman/add",
    name: "Add Handyman",
    element: <AddHandyman />,
  },
  {
    path: "/real-estate/maintenance/handyman/view/:id",
    name: "View Handyman",
    element: <ViewHandyman />,
  },
  {
    path: "/real-estate/maintenance/handyman/edit/:id",
    name: "Edit Handyman",
    element: <EditHandyman />,
  },
  {
    path: "/real-estate/maintenance/damage/list",
    name: "Damage List",
    element: <DamageReportList />,
  },
  {
    path: "/real-estate/maintenance/damage-report/add",
    name: "Add Damage",
    element: <AddDamageReport />,
  },
  {
    path: "/real-estate/maintenance/damage-report/damage/edit/:id",
    name: "Damage History",
    element: <AddDamageForm />,
  },
  // {
  //   path: "/real-estate/maintenance/damage/report-damage",
  //   name: "Damage History",
  //   element: <AddDamage />,
  // },
  {
    path: "/real-estate/maintenance/damage-report/view/:id",
    name: "View Damage",
    element: <ViewDamageReport />,
  },
  {
    path: "/real-estate/maintenance/damage/damage-history",
    name: "Damage History",
    element: <DamageHistory />,
  },
  
];
