import React, { useEffect, useRef, useState } from "react";
import Page from "../../../../shared/Page";
import CustomCard from "../../../../shared/CustomCard";
import { CustomForm } from "../../../../shared/AllInputs";
import { CustomViewInput } from "../../../../shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getEmployeeInfoAction } from "../../../../store/actions/EmployeeActions";
import { getUserInfo } from "../../../../services/auth";
import { Messages } from "primereact/messages";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import EditIcon from "../../../../assets/images/icons/editicon.png";
import { Toast } from "primereact/toast";
export default function ViewEmployee() {
  const [data, setData] = useState();

  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const msgs = useRef(null);
  const { profile } = useSelector((state) => state.user);

  useEffect(() => {
    let info = "";
    const userInfo = getUserInfo();
    profile?.firstName ? (info = profile) : (info = userInfo);
    if (info?.company) {
      getEmployeeInfo(info?.company);
    }
  }, [profile]);

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            navigate(`/administrator/employees/edit/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  const getEmployeeInfo = (company) => {
    dispatch(
      getEmployeeInfoAction(company, params?.id, (res) => {
        if (res?.success) {
          let results = res?.data?.results;
          let info = {
            employeeId: results?.employee_id,
            firstName: results?.user?.first_name,
            lastName: results?.user?.last_name,
            email: results?.user?.email,
            phoneNumber: results?.user?.phone_number,
            role: results?.role?.name,
            offices: results?.offices,
          };
          setData(info);
        } else {
          msgs.current.show({
            severity: "error",
            detail: res?.error,
          });
        }
      })
    );
  };

  const actions = () => {
    return <i className="pi pi-ellipsis-v text-gray-400"></i>;
  };

  return (
    <Page
      title="View Employee"
      description={`Let’s view your Employee Details`}
    >
      <CustomCard>
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />
        <CustomForm title="General" header={header()}>
          <CustomViewInput name="employeeId" data={data} label={"Employee ID"}/>
          <CustomViewInput name="firstName" data={data} />
          <CustomViewInput name="lastName" data={data} />
          <CustomViewInput name="email" data={data} />
          <CustomViewInput name="phoneNumber" data={data} />
        </CustomForm>
        <CustomForm title="Role">
          <CustomViewInput name="role" data={data} />
        </CustomForm>
        <CustomForm title="Office">
          <DataTable value={data?.offices} tableStyle={{ minWidth: "50rem" }}>
            <Column
              headerClassName="custom-header"
              field="name"
              header="Office"
            ></Column>
            <Column
              headerClassName="custom-header"
              field="position"
              header="Position"
            ></Column>
            <Column
              headerClassName="custom-header"
              body={actions}
              style={{ width: "80px" }}
              header="Action"
            ></Column>
          </DataTable>
        </CustomForm>
      </CustomCard>
    </Page>
  );
}
