import React, { useEffect, useRef, useState } from "react";
import CustomCard from "../../../shared/CustomCard";
import { CustomSearch } from "../../../shared/AllInputs";
import CustomButton from "../../../shared/CustomButton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import {
  officeDeleteAction,
  officeListAction,
} from "../../../store/actions/officeActions";
import { getUserInfo } from "../../../services/auth";
import { useDispatch, useSelector } from "react-redux";
import CustomOverlay from "../../../shared/CustomOverlay";
import { Messages } from "primereact/messages";
import CustomModal from "../../../shared/Modals/CustomModal";
import { Paginator } from "primereact/paginator";
import { debounce } from "lodash";
import { Toast } from "primereact/toast";
const OfficeList = () => {
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [officeList, setOfficeList] = useState();
  const [userProfile, setUserProfile] = useState();
  const { profile } = useSelector((state) => state.user);
  const msgs = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");
  const [rows, setRows] = useState(5);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [sortField, setSortField] = useState({ field: "", order: "" });
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);

  useEffect(() => {
    let info = "";
    const userInfo = getUserInfo();
    profile?.firstName ? (info = profile) : (info = userInfo);
    setUserProfile(info);
  }, [profile]);

  const getOfficeList = (company, rows, page, search, order) => {
    dispatch(
      officeListAction(company, rows, page, search, order, (res) => {
        if (res?.success) {
          const offices = res?.data?.results?.map((office) => {
            return {
              ...office,
              office_rep__user__first_name: office?.office_rep,
              office_units__name: office?.office_unit,
              employee_count: office?.employee,
            };
          });
          setOfficeList(offices);
          setCount(res?.data?.count);
          setIsSearch(false);
        }
      })
    );
  };

  const handleSearch = (company, rows, page, search, order) => {
    setIsSearch(true);
    getOfficeList(company, rows, page, search, order);
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  const header = () => {
    return (
      <div className="w-12 md:w-7 flex justify-content-end gap-2 flex-wrap">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            value={search}
            onChange={({ value }) => {
              setSearch(value)
              setRows(5);
              setPage(1);
              setFirst(0);
              debouncedOnChange.current(
                userProfile?.company,
                rows,
                page,
                value,
                sortField?.order + sortField?.field
              );

            }}
            isSearch={isSearch}
          />
        </div>
        <CustomButton
          size="small"
          icon="pi pi-plus"
          label="Add Office"
          onClick={() => navigate("/administrator/office/add")}
        />
      </div>
    );
  };

  const actions = (d) => {
    return (
      <CustomOverlay>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => navigate(`/administrator/office/view/${d.id}`)}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          <li className="flex gap-2 text-xs font-medium mb-3">
            <i
              className="pi pi-trash cursor-pointer text-dark"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}
            ></i>{" "}
            Delete
          </li>
        </ul>
      </CustomOverlay>
    );
  };

  const handleOfficeDelete = () => {
    dispatch(
      officeDeleteAction(
        userProfile?.company,
        idToDelete,
        setLoading,
        setDeleteModal,
        (res) => {
          if (res?.success) {
            msgs?.current?.show({
              severity: "success",
              detail: res?.message,
            });
            getOfficeList(
              userProfile?.company,
              rows,
              page,
              search,
              sortField?.order + sortField?.field
            );
          } else {
            msgs?.current?.show({
              severity: "error",
              detail: res?.message,
            });
          }
        }
      )
    );
  };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected office from the list? This
        action cannot be undone.
      </span>
    );
  };

  const onPageChange = (e) => {
    setRows(e.rows);
    setPage(e.page + 1);
    setFirst(e.first);
  };

  useEffect(() => {
    if (userProfile?.company) {
        getOfficeList(
          userProfile?.company,
          rows,
          page,
          "",
          sortField?.order + sortField?.field
        );
    }
  }, [rows, page, first, "", userProfile?.company, sortField]);

  const handleSort = (e) => {
    if (sortField?.order === "-") {
      setSortField({ field: e?.sortField, order: "" });
    } else {
      setSortField({ field: e?.sortField, order: "-" });
    }
  };

  return (
    <div>
      <CustomCard extraClass title="Office List" header={header()}>
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />
        <DataTable
          value={officeList}
          tableStyle={{ minWidth: "50rem" }}
          selectionMode={rowClick ? null : "checkbox"}
          selection={selectedProducts}
          scrollable
          className="mt-4"
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          onSort={handleSort}
        >
          <Column
            headerClassName="custom-header"
            selectionMode="multiple"
            headerStyle={{ width: "100px" }}
          ></Column>
          <Column
            className="firstletter_capital"
            headerClassName="custom-header"
            field="office_id"
            header="Office ID"
            headerStyle={{ width: "100px" }}
            sortable
          ></Column>
          <Column
            className="firstletter_capital"
            headerClassName="custom-header"
            field="name"
            header="Name"
            headerStyle={{ width: "50px", wordWrap: "break-word" }}
            style={{ maxWidth: "100px", wordWrap: "break-word" }}
            sortable
          ></Column>
          <Column
            className="firstletter_capital"
            headerClassName="custom-header"
            field="address"
            header="Address"
            headerStyle={{ width: "3rem" }}
            sortable
          ></Column>
          <Column
            className="firstletter_capital"
            headerClassName="custom-header"
            field="office_units__name"
            header="Unit"
            headerStyle={{ width: "3rem" }}
            sortable
          ></Column>
          <Column
            className="firstletter_capital"
            headerClassName="custom-header"
            field="office_rep__user__first_name"
            header="Rep Name"
            headerStyle={{ width: "3rem" }}
            sortable
          ></Column>
          <Column
            className="firstletter_capital"
            headerClassName="custom-header"
            field="employee_count"
            header="Employees"
            headerStyle={{ width: "3rem" }}
            sortable
          ></Column>
          <Column
            className="firstletter_capital"
            headerClassName="custom-header"
            field="office_email"
            header="Email"
            headerStyle={{ width: "3rem" }}
            sortable
          ></Column>
          <Column
            headerClassName="custom-header"
            body={actions}
            style={{ width: "80px" }}
            header="Action"
          ></Column>
        </DataTable>
        <Paginator
          first={first}
          rows={rows}
          totalRecords={count}
          rowsPerPageOptions={[5, 10, 20, 50]}
          onPageChange={onPageChange}
          // lazy
        ></Paginator>
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        onSubmit={handleOfficeDelete}
        loading={loading}
      />
    </div>
  );
};

export default OfficeList;
