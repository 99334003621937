import React, { useEffect, useMemo, useRef, useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import CustomButton, {
  CustomButtonOutlined,
} from "../../../../../shared/CustomButton";
import CustomCard from "../../../../../shared/CustomCard";
import CustomTableContainer from "../../../../Tables/CustomTableContainer";
import { CustomInput, CustomSearch } from "../../../../../shared/AllInputs";
import { Tooltip } from "primereact/tooltip";
import { truncateText } from "../../../../../utils/regex";
import { Dropdown } from "primereact/dropdown";
import { BuildingStatus } from "../../../../../shared/AllDropDownOptions";
import { useNavigate } from "react-router-dom";
import {
  propertiesListAction,
  propertiesListActionModal,
} from "../../../../../store/actions/propertyActions";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash.debounce";
import { getUserInfo } from "../../../../../services/auth";
import { setUserProfile } from "../../../../../store/slices/userSlice";

const PropertyTableModal = ({
  isOpen,
  setIsOpen,
  heading,
  cancelLabel,
  submitLabel,
  loading,
  onSubmit,
  onCancel,
  selectedProperty,
  setSelectedProperty,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.user);
  const [search, setSearch] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [propertyList, setPropertyList] = useState([]);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState();
  const globalFilters = ["property_id", "contractId", "address", "status"];
  const [filters, setFilters] = useState({
    property_id: { value: "" },
    contractId: { value: "" },
    address: { value: "" },
    status: { value: "" },
  });

  useEffect(() => {
    if (!profile.company) {
      let info = getUserInfo();
      dispatch(setUserProfile(info));
    }
  }, [profile]);

  const footerContent = (
    <div className="modal-footer border-0 gap-4 flex justify-content-center pb-4">
      <CustomButtonOutlined
        disabled={loading}
        type="button"
        label={cancelLabel || "Cancel"}
        className="btn m-0 fs-6 p-2 px-5 primary-button-outlined bg-transparent "
        onClick={() => {
          setIsOpen(!isOpen);
          onCancel && onCancel();
        }}
      />
      {onSubmit && (
        <CustomButton
          type="button"
          loading={loading}
          label={submitLabel || "Send"}
          onClick={onSubmit}
          className="btn primary-button fs-6 p-2 text-white px-5"
        />
      )}
    </div>
  );

  const header = () => {
    return (
      <div className="w-12 md:w-7 flex align-items-center justify-content-end flex-wrap">
        <div className="w-12 md:w-8 mr-3">
          <CustomSearch
            name="search"
            data={search}
            onChange={(e) => setSearch(e?.value)}
            isSearch={isSearch}
            placeholder="Search Damage"
          />
        </div>
      </div>
    );
  };

  const PropertyIDFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="property_id"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Property ID"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const ContractIDFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="address"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Property ID"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const AddressFilter = (options) => {
    return (
      <div>
        <CustomInput
          col={12}
          value={options.value}
          name="address"
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          className="p-column-filter"
          ignoreLabel
        />
      </div>
    );
  };

  const addressBodyTemplate = (options) => {
    const tooltipId = `address-tooltip-${options?.id}`;
    return (
      <div>
        <Tooltip
          className="tooltip"
          target={`.${tooltipId}`}
          content={options?.address}
        />
        <span
          className={tooltipId}
          data-pr-classname="tooltip"
          style={{ cursor: "pointer" }}
        >
          <span
          // style={{
          //   color: options?.id === handyman?.id ? "white" : null,
          // }}
          >
            {truncateText(options?.address, 20)}
          </span>
        </span>
      </div>
    );
  };

  const StatusFilter = (options) => {
    return (
      <div>
        <Dropdown
          value={options.value}
          options={BuildingStatus}
          onChange={(e) => {
            options.filterCallback(e.value);
          }}
          optionLabel="name"
          placeholder="Status"
          className="p-column-filter"
        />
      </div>
    );
  };

  const statusBodyTemplate = (options) => {
    return (
      <span className={getStatusClass(options?.status)}>{options?.status}</span>
    );
  };

  const getStatusClass = (status) => {
    switch (status) {
      case "Vaccant":
      case "Fully":
        return "text-yellow-500";

      case "Expiring":
      case "Moving Out":
      case "Pending":
        return "text-red-600";

      case "Occupied":
      case "Partially":
        return "text-green-600";

      default:
        return "";
    }
  };

  const columns = useMemo(
    () => [
      {
        name: "Property ID",
        accessor: "property_id",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        filterElement: (option) => PropertyIDFilter(option),
      },
      {
        name: "Contract ID",
        accessor: "name",
        value: true,
        frozen: true,
        sortable: false,
        filter: true,
        filterElement: (option) => ContractIDFilter(option),
      },
      {
        name: "Address",
        accessor: "address",
        value: true,
        filter: true,
        body: (options) => addressBodyTemplate(options),
        filterElement: (option) => AddressFilter(option),
      },
      {
        name: "Status",
        accessor: "status",
        value: true,
        filter: true,
        body: (options) => statusBodyTemplate(options),
        filterElement: (options) => StatusFilter(options),
      },
    ],
    []
  );

  const onPageChange = (e) => {
    setPage(e.page + 1);
    setRows(e.rows);
    setFirst(e.first);
  };

  const getPropertiesList = (
    company,
    rows,
    page,
    search,
    propertyId,
    contractId,
    address,
    status
  ) => {
    dispatch(
      propertiesListActionModal(
        company,
        rows,
        page,
        search,
        propertyId,
        contractId,
        address,
        status,
        (res) => {
          setPropertyList(res?.options);
          setCount(res?.count);
          setIsSearch(false);
        }
      )
    );
  };

  const handleSearch = (
    company,
    rows,
    page,
    search,
    propertyId,
    contractId,
    address,
    status
  ) => {
    setIsSearch(true);
    getPropertiesList(
      company,
      rows,
      page,
      search,
      propertyId,
      contractId,
      address,
      status
    );
  };

  const debouncedOnChange = useRef(debounce(handleSearch, 1000));

  useEffect(() => {
    if (profile?.company) {
      if (search) {
        debouncedOnChange.current(
          profile?.company,
          rows,
          page,
          search,
          filters?.property_id?.value,
          filters?.contractId?.value,
          filters?.address?.value,
          filters?.status?.value
        );
      } else {
        getPropertiesList(
          profile?.company,
          rows,
          page,
          search,
          filters?.property_id?.value,
          filters?.contractId?.value,
          filters?.address?.value,
          filters?.status?.value
        );
      }
    }
  }, [
    rows,
    page,
    first,
    search,
    profile?.company,
    filters?.property_id?.value,
    filters?.contractId?.value,
    filters?.address?.value,
    filters?.status?.value,
  ]);

  return (
    <Dialog
      visible={isOpen}
      className="invitation-modal"
      header={heading}
      footer={footerContent}
      style={{ width: "30vw" }}
      breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      onHide={() => {
        !loading && setIsOpen(false);
        onCancel && onCancel();
      }}
    >
      <CustomCard title=" " header={header()}>
        <CustomTableContainer
          list={propertyList}
          columns={columns}
          actions={""}
          first={first}
          rows={rows}
          count={count}
          ActionTemplate={""}
          activeRow={""}
          slice={""}
          globalFilters={globalFilters}
          handleSort={""}
          onPageChange={onPageChange}
          filters={filters}
          setFilters={setFilters}
          selectionMode={"single"}
          selectedProducts={selectedProperty}
          setSelectedProducts={setSelectedProperty}
        />
      </CustomCard>
    </Dialog>
  );
};

export default PropertyTableModal;
