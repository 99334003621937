import React, { useRef, useState } from "react";
import InputLayout from "./InputLayout";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";
import { Dropdown } from "primereact/dropdown";
import { Password } from "primereact/password";
import { Calendar } from "primereact/calendar";
import { InputSwitch } from "primereact/inputswitch";
import { capitalizeCamelCase } from "../utils/commonFunctions";
import { InputOtp } from "primereact/inputotp";
import { MultiSelect } from "primereact/multiselect";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
// import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { InputNumber } from "primereact/inputnumber";
// import { startsWith } from "lodash";
import { AutoComplete } from "primereact/autocomplete";
import { number } from "../utils/regex";
import { NumericFormat } from "react-number-format";
import { InputTextarea } from 'primereact/inputtextarea';

export const CustomInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  isSearch,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="input-box ">
        <InputText
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          type={type}
          onChange={(e) =>
            onChange &&
            onChange({ ...e, name: e.target.name, value: e.target.value })
          }
          className={`input w-full ${inputClass ? inputClass : ""} ${
            errorMessage ? "p-invalid" : ""
          }`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
          {...props}
        />
        {isSearch && <i className="pi pi-spin pi-spinner" />}
        {spanLabel && <span className="p-inputgroup-addon">{spanLabel}</span>}
      </div>
    </InputLayout>
  );
};

export const CustomSizeM2 = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  spanExtraClassName,
  max,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="p-inputgroup flex">
        <InputText
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          type={type}
          onChange={(e) => {
            if (onChange) {
              if (number(e.target.value)) {
                onChange({
                  ...e,
                  name: e.target.name,
                  value: e.target.value,
                });
              } else if (!e.target.value) {
                onChange({
                  ...e,
                  name: e.target.name,
                  value: e.target.value,
                });
              }
            }
          }}
          className={`input w-full ${inputClass ? inputClass : ""} ${
            errorMessage ? "p-invalid" : ""
          }`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
          maxLength={max}
          {...props}
        />
        <span className="p-inputgroup-addon p-1">
          <svg
            width="35"
            height="35"
            viewBox="0 0 96 101"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="96" height="101" />
            <path
              d="M33.7614 62V42.3636H36.6761V45.4318H36.9318C37.3409 44.3835 38.0014 43.5696 38.9134 42.9901C39.8253 42.402 40.9205 42.108 42.1989 42.108C43.4943 42.108 44.5724 42.402 45.4332 42.9901C46.3026 43.5696 46.9801 44.3835 47.4659 45.4318H47.6705C48.1733 44.4176 48.9276 43.6122 49.9332 43.0156C50.9389 42.4105 52.1449 42.108 53.5511 42.108C55.3068 42.108 56.7429 42.6577 57.8594 43.7571C58.9759 44.848 59.5341 46.5483 59.5341 48.858V62H56.517V48.858C56.517 47.4091 56.1207 46.3736 55.3281 45.7514C54.5355 45.1293 53.6023 44.8182 52.5284 44.8182C51.1477 44.8182 50.0781 45.2358 49.3196 46.071C48.5611 46.8977 48.1818 47.946 48.1818 49.2159V62H45.1136V48.5511C45.1136 47.4347 44.7514 46.5355 44.027 45.8537C43.3026 45.1634 42.3693 44.8182 41.2273 44.8182C40.4432 44.8182 39.7102 45.027 39.0284 45.4446C38.3551 45.8622 37.8097 46.4418 37.392 47.1832C36.983 47.9162 36.7784 48.7642 36.7784 49.7273V62H33.7614Z"
              fill="black"
            />
            <path
              d="M63.5057 43V41.7216L68.3068 36.4659C68.8703 35.8504 69.3343 35.3153 69.6989 34.8608C70.0634 34.4015 70.3333 33.9706 70.5085 33.5682C70.6884 33.161 70.7784 32.7348 70.7784 32.2898C70.7784 31.7784 70.6553 31.3357 70.4091 30.9616C70.1676 30.5876 69.8362 30.2988 69.4148 30.0952C68.9934 29.8916 68.5199 29.7898 67.9943 29.7898C67.4356 29.7898 66.9479 29.9058 66.5312 30.1378C66.1193 30.3651 65.7997 30.6847 65.5724 31.0966C65.3499 31.5085 65.2386 31.9915 65.2386 32.5455H63.5625C63.5625 31.6932 63.759 30.9451 64.152 30.3011C64.545 29.6572 65.08 29.1553 65.7571 28.7955C66.4389 28.4356 67.2036 28.2557 68.0511 28.2557C68.9034 28.2557 69.6586 28.4356 70.3168 28.7955C70.9749 29.1553 71.491 29.6406 71.8651 30.2514C72.2391 30.8622 72.4261 31.5417 72.4261 32.2898C72.4261 32.8248 72.3291 33.348 72.1349 33.8594C71.9455 34.366 71.6141 34.9318 71.1406 35.5568C70.6719 36.1771 70.0208 36.9347 69.1875 37.8295L65.9205 41.3239V41.4375H72.6818V43H63.5057Z"
              fill="black"
            />
          </svg>
        </span>
      </div>
    </InputLayout>
  );
};

export const CustomNumberInput2 = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  spanExtraClassName,
  max,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="p-inputgroup flex">
        <InputText
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          type={type}
          onChange={(e) => {
            if (onChange) {
              if (number(e.target.value)) {
                onChange({ ...e, name: e.target.name, value: e.target.value });
              } else if (!e.target.value) {
                onChange({ ...e, name: e.target.name, value: e.target.value });
              }
            }
          }}
          className={`input w-full ${inputClass ? inputClass : ""} ${
            errorMessage ? "p-invalid" : ""
          }`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
          maxLength={max}
          {...props}
        />
        {spanLabel && (
          <span
            className={`p-inputgroup-addon ${
              spanExtraClassName ? spanExtraClassName : ""
            }`}
          >
            {spanLabel === "m2" ? (
              <svg
                width="35"
                height="35"
                viewBox="0 0 96 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="96" height="101" />
                <path
                  d="M33.7614 62V42.3636H36.6761V45.4318H36.9318C37.3409 44.3835 38.0014 43.5696 38.9134 42.9901C39.8253 42.402 40.9205 42.108 42.1989 42.108C43.4943 42.108 44.5724 42.402 45.4332 42.9901C46.3026 43.5696 46.9801 44.3835 47.4659 45.4318H47.6705C48.1733 44.4176 48.9276 43.6122 49.9332 43.0156C50.9389 42.4105 52.1449 42.108 53.5511 42.108C55.3068 42.108 56.7429 42.6577 57.8594 43.7571C58.9759 44.848 59.5341 46.5483 59.5341 48.858V62H56.517V48.858C56.517 47.4091 56.1207 46.3736 55.3281 45.7514C54.5355 45.1293 53.6023 44.8182 52.5284 44.8182C51.1477 44.8182 50.0781 45.2358 49.3196 46.071C48.5611 46.8977 48.1818 47.946 48.1818 49.2159V62H45.1136V48.5511C45.1136 47.4347 44.7514 46.5355 44.027 45.8537C43.3026 45.1634 42.3693 44.8182 41.2273 44.8182C40.4432 44.8182 39.7102 45.027 39.0284 45.4446C38.3551 45.8622 37.8097 46.4418 37.392 47.1832C36.983 47.9162 36.7784 48.7642 36.7784 49.7273V62H33.7614Z"
                  fill="black"
                />
                <path
                  d="M63.5057 43V41.7216L68.3068 36.4659C68.8703 35.8504 69.3343 35.3153 69.6989 34.8608C70.0634 34.4015 70.3333 33.9706 70.5085 33.5682C70.6884 33.161 70.7784 32.7348 70.7784 32.2898C70.7784 31.7784 70.6553 31.3357 70.4091 30.9616C70.1676 30.5876 69.8362 30.2988 69.4148 30.0952C68.9934 29.8916 68.5199 29.7898 67.9943 29.7898C67.4356 29.7898 66.9479 29.9058 66.5312 30.1378C66.1193 30.3651 65.7997 30.6847 65.5724 31.0966C65.3499 31.5085 65.2386 31.9915 65.2386 32.5455H63.5625C63.5625 31.6932 63.759 30.9451 64.152 30.3011C64.545 29.6572 65.08 29.1553 65.7571 28.7955C66.4389 28.4356 67.2036 28.2557 68.0511 28.2557C68.9034 28.2557 69.6586 28.4356 70.3168 28.7955C70.9749 29.1553 71.491 29.6406 71.8651 30.2514C72.2391 30.8622 72.4261 31.5417 72.4261 32.2898C72.4261 32.8248 72.3291 33.348 72.1349 33.8594C71.9455 34.366 71.6141 34.9318 71.1406 35.5568C70.6719 36.1771 70.0208 36.9347 69.1875 37.8295L65.9205 41.3239V41.4375H72.6818V43H63.5057Z"
                  fill="black"
                />
              </svg>
            ) : (
              spanLabel
            )}
          </span>
        )}
      </div>
    </InputLayout>
  );
};

export const CustomAmountInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  spanExtraClassName,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="p-inputgroup flex w-full">
        <NumericFormat
          value={value || data?.[name]}
          thousandSeparator="."
          decimalSeparator=","
          // decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
          onValueChange={(e) => {
            if (onChange) {
              if (number(e.value)) {
                onChange({ ...e, name: name, value: e.value });
              } else if (!e.value) {
                onChange({ ...e, name: name, value: e.value });
              }
            }
          }}
          // prefix="₹"
          className={`input border-1 numeric_input w-full ${
            inputClass ? inputClass : ""
          } ${errorMessage ? "p-invalid" : ""}`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
        />
        {spanLabel && (
          <span
            className={`p-inputgroup-addon ${
              spanExtraClassName ? spanExtraClassName : ""
            }`}
          >
            {spanLabel === "m2" ? (
              <svg
                width="35"
                height="35"
                viewBox="0 0 96 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="96" height="101" />
                <path
                  d="M33.7614 62V42.3636H36.6761V45.4318H36.9318C37.3409 44.3835 38.0014 43.5696 38.9134 42.9901C39.8253 42.402 40.9205 42.108 42.1989 42.108C43.4943 42.108 44.5724 42.402 45.4332 42.9901C46.3026 43.5696 46.9801 44.3835 47.4659 45.4318H47.6705C48.1733 44.4176 48.9276 43.6122 49.9332 43.0156C50.9389 42.4105 52.1449 42.108 53.5511 42.108C55.3068 42.108 56.7429 42.6577 57.8594 43.7571C58.9759 44.848 59.5341 46.5483 59.5341 48.858V62H56.517V48.858C56.517 47.4091 56.1207 46.3736 55.3281 45.7514C54.5355 45.1293 53.6023 44.8182 52.5284 44.8182C51.1477 44.8182 50.0781 45.2358 49.3196 46.071C48.5611 46.8977 48.1818 47.946 48.1818 49.2159V62H45.1136V48.5511C45.1136 47.4347 44.7514 46.5355 44.027 45.8537C43.3026 45.1634 42.3693 44.8182 41.2273 44.8182C40.4432 44.8182 39.7102 45.027 39.0284 45.4446C38.3551 45.8622 37.8097 46.4418 37.392 47.1832C36.983 47.9162 36.7784 48.7642 36.7784 49.7273V62H33.7614Z"
                  fill="black"
                />
                <path
                  d="M63.5057 43V41.7216L68.3068 36.4659C68.8703 35.8504 69.3343 35.3153 69.6989 34.8608C70.0634 34.4015 70.3333 33.9706 70.5085 33.5682C70.6884 33.161 70.7784 32.7348 70.7784 32.2898C70.7784 31.7784 70.6553 31.3357 70.4091 30.9616C70.1676 30.5876 69.8362 30.2988 69.4148 30.0952C68.9934 29.8916 68.5199 29.7898 67.9943 29.7898C67.4356 29.7898 66.9479 29.9058 66.5312 30.1378C66.1193 30.3651 65.7997 30.6847 65.5724 31.0966C65.3499 31.5085 65.2386 31.9915 65.2386 32.5455H63.5625C63.5625 31.6932 63.759 30.9451 64.152 30.3011C64.545 29.6572 65.08 29.1553 65.7571 28.7955C66.4389 28.4356 67.2036 28.2557 68.0511 28.2557C68.9034 28.2557 69.6586 28.4356 70.3168 28.7955C70.9749 29.1553 71.491 29.6406 71.8651 30.2514C72.2391 30.8622 72.4261 31.5417 72.4261 32.2898C72.4261 32.8248 72.3291 33.348 72.1349 33.8594C71.9455 34.366 71.6141 34.9318 71.1406 35.5568C70.6719 36.1771 70.0208 36.9347 69.1875 37.8295L65.9205 41.3239V41.4375H72.6818V43H63.5057Z"
                  fill="black"
                />
              </svg>
            ) : (
              spanLabel
            )}
          </span>
        )}
      </div>
    </InputLayout>
  );
};

export const CustomNumberInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  style,
  ignoreLabel,
  min,
  max,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <InputNumber
        id={name}
        name={name}
        value={value || data?.[name] || 0}
        type={type}
        onValueChange={(e) =>
          onChange && onChange({ ...e, name: e?.target?.name, value: e.value })
        }
        className={`input w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        mode="decimal"
        disabled={disabled}
        style={style}
        {...props}
        step={1}
        min={min}
        max={max}
      />
    </InputLayout>
  );
};

export const CustomFileInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  inputKey,
  selectedFiles,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      {/* <InputText
        id={name}
        name={name}
        type={type}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.files[0] })
        }
        className={`input w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        disabled={disabled}
        {...props}
        multiple={true}
        accept=".png, .jpg, .jpeg"
      /> */}
      <input
        disabled={disabled}
        key={inputKey}
        id="file-upload"
        type="file"
        accept=".jpg,.jpeg,.png,.pdf,.doc,.xls,.zip"
        onChange={onChange}
        onClick={(event) => {
          if (
            event.target.files.length === 1 &&
            event.target.files[0].name ===
              selectedFiles[selectedFiles.length - 1]?.name
          ) {
            event.target.value = null;
          }
        }}
        // multiple
      />
    </InputLayout>
  );
};

export const CustomPhoneInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled,
  type = "text",
  placeholder = "",
  ignoreLabel,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <PhoneInput
        enableSearch
        disabled={disabled}
        id={name}
        name={name}
        defaultCountry="DE"
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        value={value || data[name]}
        className={`input w-full phone-input ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        onChange={(value) => {
          onChange({ name: name, value: value });
        }}
      />{" "}
    </InputLayout>
  );
};

export const CustomPassword = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 12,
  inputClass,
  disabled = false,
  placeholder = "",
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <Password
        id={name}
        name={name}
        value={value || data?.[name] || ""}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        className={` ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        disabled={disabled}
        feedback={false}
        toggleMask
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        {...props}
      />
    </InputLayout>
  );
};

export const CustomDropDown = ({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  optionLabel = "name",
  placeholder = "",
  multiple,
  ignoreLabel,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <Dropdown
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        className={`w-full custom-dropdown ${errorMessage ? "p-invalid" : ""}`}
        optionLabel={optionLabel}
        placeholder={placeholder || `Select ${capitalizeCamelCase(name)}`}
        {...props}
        // filter
      />
    </InputLayout>
  );
};

export const CustomAutoComplete = ({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  optionLabel = "name",
  placeholder = "",
  multiple,
  ignoreLabel,
  suggestions,
  search,
  onClick,
  max,
  onLazyLoad,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <AutoComplete
        name={name}
        value={value || data?.[name]}
        suggestions={suggestions}
        completeMethod={search}
        inputClassName="w-full"
        className={`w-full custom-dropdown ${errorMessage ? "p-invalid" : ""}`}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        {...props}
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        maxLength={max}
        // virtualScrollerOptions={{ lazy: true, showLoader: true, itemSize: 10, onLazyLoad: onLazyLoad && onLazyLoad }}
      />
    </InputLayout>
  );
};

export const CustomMulitSelect = ({
  label,
  name,
  onChange,
  data,
  value,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  optionLabel = "name",
  placeholder = "",
  multiple,
  ignoreLabel,
  options,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <MultiSelect
        id={name}
        name={name}
        value={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.value })
        }
        options={options}
        placeholder={placeholder || `Select ${capitalizeCamelCase(name)}`}
        maxSelectedLabels={3}
        className={`w-full custom-dropdown ${errorMessage ? "p-invalid" : ""}`}
        optionLabel={optionLabel}
      />
    </InputLayout>
  );
};

export const CustomCalander = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  placeholder = "",
  ignoreLabel,
  ...props
}) => {
  const calendarRef = useRef(null);

  const handleIconClick = () => {
    if (calendarRef.current) {
      calendarRef.current.focus(); // Focus the calendar to open it
    }
  };

  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="calendar-box">
        <Calendar
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          onChange={(e) => onChange && onChange({ ...e, name, value: e.value })}
          className={`w-full ${inputClass ? inputClass : ""}`}
          disabled={disabled}
          placeholder={placeholder || `Select ${capitalizeCamelCase(name)}`}
          ref={calendarRef}
          {...props}
        />
        <i
          className="pi pi-calendar"
          onClick={handleIconClick}
          style={{ cursor: "pointer" }}
        />
      </div>
    </InputLayout>
  );
};

export const CustomCheckbox = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  template,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      name=""
      extraClassName={extraClassName}
      errorMessage={data?.formErrors?.[name]}
    >
      <Checkbox
        id={name}
        name={name}
        inputId={label}
        checked={value || data?.[name]}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e.target.name, value: e.checked })
        }
        className={`checkbox ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        {...props}
      />

      {label && (
        <label htmlFor={label} className="ml-2">
          {label}
        </label>
      )}
      {template && <>{template}</>}
    </InputLayout>
  );
};

export const CustomRadioInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col,
  inputClass,
  template,
  checked,
  ...props
}) => {
  return (
    <InputLayout
      col={col || 6}
      name=""
      extraClassName={extraClassName}
      errorMessage={data?.formErrors?.[name]}
    >
      <Checkbox
        id={`${name}-${value}`}
        name={name}
        value={value}
        inputId={label}
        checked={checked}
        onChange={(e) =>
          onChange && onChange({ ...e, name: e?.target?.name, value: e.value })
        }
        className={`checkbox ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        {...props}
      />
      {label && (
        <label htmlFor={`${name}-${value}`} className="ml-2">
          {label}
        </label>
      )}
      {template && <>{template}</>}
    </InputLayout>
  );
};

export const CustomSwitch = ({
  label,
  name,
  data,
  value,
  onChange,
  col = 6,
  ...props
}) => {
  return (
    <InputLayout col={col} name="">
      <div className="flex">
        <InputSwitch
          checked={value || data?.[name]}
          onChange={(e) => onChange && onChange({ ...e, name, value: e.value })}
          {...props}
        />
        <div htmlFor={label} className="ml-2 my-auto">
          {label ? capitalizeCamelCase(label) : label}
        </div>
      </div>
    </InputLayout>
  );
};

//New
export const CustomSearch = ({
  name,
  data,
  value,
  onChange,
  isSearch,
  placeholder,
}) => {
  return (
    <div className="search-box">
      <InputText
        id={name}
        className="w-full"
        placeholder={placeholder || "Search"}
        name={name}
        value={value || data?.[name] || ""}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
      />
      {!isSearch ? (
        <i className="pi pi-search" />
      ) : (
        <i className="pi pi-spin pi-spinner" />
      )}
    </div>
  );
};

export const CustomOtpInput = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 12,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputOtp
        integerOnly
        length={6}
        value={value || data?.[name] || ""}
        name={name}
        onChange={(e) =>
          onChange && onChange({ ...e, name: name, value: e?.value })
        }
      />
    </InputLayout>
  );
};

export const CustomAddSubtractInput = ({
  label,
  name,
  data,
  // value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  ...props
}) => {
  const [value, setValue] = useState(0);

  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
    >
      <InputText
        id={name}
        name={name}
        value={value || data?.[name] || ""}
        type={type}
        onChange={(e) =>
          onChange &&
          onChange({ ...e, name: e.target.name, value: e.target.value })
        }
        className={`input w-full ${inputClass ? inputClass : ""} ${
          errorMessage ? "p-invalid" : ""
        }`}
        placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
        disabled={disabled}
        {...props}
      />
    </InputLayout>
  );
};

export const CustomForm = ({ children, title, header }) => {
  return (
    <form className="grid m-0 p-0">
      {title ? <div className="col-10 title my-auto">{title}</div> : null}
      {header ? <div className="col title my-auto">{header}</div> : null}
      {children}
    </form>
  );
};

// export const CustomInputMask = ({
//   label,
//   name,
//   data,
//   value,
//   onChange,
//   errorMessage,
//   extraClassName,
//   required,
//   col = 6,
//   inputClass,
//   disabled = false,
//   type = "text",
//   placeholder = "",
//   spanLabel,
//   ignoreLabel,
//   ...props
// }) => {
//   return (
//     <InputLayout
//       col={col}
//       label={label}
//       name={name}
//       required={required}
//       extraClassName={extraClassName}
//       data={data}
//       errorMessage={errorMessage}
//       ignoreLabel={ignoreLabel}
//     >
//       <div className="p-inputgroup flex-1">
//         <InputNumber
//           id={name}
//           name={name}
//           value={value || data?.[name] || 0}
//           type={type}
//           onValueChange={(e) =>
//             onChange && onChange({ ...e, name: e.target.name, value: e.value })
//           }
//           className={`input w-full ${inputClass ? inputClass : ""} ${
//             errorMessage ? "p-invalid" : ""
//           }`}
//           placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
//           disabled={disabled}
//           {...props}
//         />
//         {spanLabel && <span className="p-inputgroup-addon">{spanLabel}</span>}
//       </div>
//     </InputLayout>
//   );
// };

export const CustomInputMask = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  max,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="p-inputgroup flex-1">
        <InputText
          id={name}
          name={name}
          value={value || data?.[name]}
          type={type}
          onChange={(e) =>
            onChange &&
            onChange({ ...e, name: e.target.name, value: e.target.value })
          }
          className={`input w-full ${inputClass ? inputClass : ""} ${
            errorMessage ? "p-invalid" : ""
          }`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
          maxLength={max}
          {...props}
        />
        {spanLabel && <span className="p-inputgroup-addon">{spanLabel}</span>}
      </div>
    </InputLayout>
  );
};

export const CustomTextArea = ({
  label,
  name,
  data,
  value,
  onChange,
  errorMessage,
  extraClassName,
  required,
  col = 6,
  inputClass,
  disabled = false,
  type = "text",
  placeholder = "",
  spanLabel,
  ignoreLabel,
  isSearch,
  ...props
}) => {
  return (
    <InputLayout
      col={col}
      label={label}
      name={name}
      required={required}
      extraClassName={extraClassName}
      data={data}
      errorMessage={errorMessage}
      ignoreLabel={ignoreLabel}
    >
      <div className="input-box ">
        <InputTextarea
          id={name}
          name={name}
          value={value || data?.[name] || ""}
          type={type}
          rows={5} 
          cols={30}
          onChange={(e) =>
            onChange &&
            onChange({ ...e, name: e.target.name, value: e.target.value })
          }
          className={`input w-full ${inputClass ? inputClass : ""} ${
            errorMessage ? "p-invalid" : ""
          }`}
          placeholder={placeholder || `Enter ${capitalizeCamelCase(name)}`}
          disabled={disabled}
          {...props}
        />
        {isSearch && <i className="pi pi-spin pi-spinner" />}
        {spanLabel && <span className="p-inputgroup-addon">{spanLabel}</span>}
      </div>
    </InputLayout>
  );
};