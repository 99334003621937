import api from "../../services/api";
import endPoints from "../../services/endPoints";

const addRoleAction = (company, data, loading, navigate, onError) => async () => {
    loading(true)
    const res = await api(
        "post",
        endPoints?.COMPANY_ROLES + `${company}/`,
        data
    );
    if (res?.success) {
        navigate("/administrator/roles/list")
    } else {
        onError(res?.message);
    }
    loading(false)
};

const updateRoleAction = (company, roleId, data, loading, navigate, onError) => async () => {
    loading(true)
    const res = await api(
        "put",
        endPoints?.COMPANY_ROLES + `${company}/${roleId}/`,
        data
    );
    if (res?.success) {
        // navigate(`/administrator/roles/view/${roleId}`)
        navigate(`/administrator/roles/list`)
    } else {
        onError(res?.message);
    }
    loading(false)
};

const getRolesListAction = (company, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.ROLES_LIST + `${company}/`,
    );
    onRes(res);
};


const getRoleInfoAction = (company, roleId, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.COMPANY_ROLES + `${company}/${roleId}`,
    );
    onRes(res);
};

const getRoleEmployeesAction = (company, roleId, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.ROLES_LIST + `${company}/${roleId}/`,
    );
    onRes(res);
};

const roleDeleteAction = (company, roleId, loading, deleteModal, onRes) => async () => {
    loading(true)
    const res = await api(
        "delete",
        endPoints?.COMPANY_ROLES + `${company}/${roleId}`,
    );
    onRes(res);
    loading(false)
    deleteModal(false)
};

const changeEmployeeRole = (employeeId, payload, loading, onRes) => async () => {
    loading(true)
    const res = await api(
        "patch",
        endPoints?.EMP_ROLE_CHANGE + `${employeeId}/`, payload
    );
    onRes(res);
    loading(false)
};

export {
    addRoleAction,
    getRolesListAction,
    getRoleInfoAction,
    getRoleEmployeesAction,
    updateRoleAction,
    roleDeleteAction,
    changeEmployeeRole
};
