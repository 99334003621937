import api from "../../services/api";
import endPoints from "../../services/endPoints";
import { handleFileConversion } from "../../utils/commonFunctions";
import { showToastAction } from "../slices/commonSlice";
import { setDamageReportSlice } from "../slices/damageSlice";

//damage report
const addDamageReportAction = (company, propertyId, allDamages, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    const damages = await Promise.all(allDamages.map(async (damage) => ({
        damage_cause: damage.damageCause,
        damage_type: damage.damageType,
        description: damage.damageDescription,
        images: await Promise.all(damage.document.map(async (doc) => ({
            image: await handleFileConversion(doc.document)
        })))
    })));

    let payload = {
        property: propertyId,
        damages: damages,
    }
    const res = await api(
        "post",
        endPoints?.DAMAGE_REPORT + `/${company}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const updateDamageReportAction = (company, reportId, propertyData, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        status: propertyData?.status,
    }
    const res = await api(
        "patch",
        endPoints?.DAMAGE_REPORT + `/${company}/${reportId}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const getDamageReportListAction = (company, rows, page, search, status, propertyId, numberDamages, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.DAMAGE_REPORT + `/${company}/?limit=${rows}&page=${page}&search=${search}&status=${status || ""}&property_id=${propertyId || ""}&number_damages=${numberDamages || ""}`,
    );

    if (res?.success) {
        const info = res?.data?.results?.map((item) => {
            return {
                property_id: item?.property?.property_id,
                ...item
            }
        })
        onRes({ options: info, count: res?.data?.count })
    }
};

const deleteDamageReportAction = (company, id, setLoading, setDeleteModal, dispatch, onRes) => async () => {
    setLoading(true)
    const res = await api(
        "delete",
        endPoints?.DAMAGE_REPORT + `/${company}/${id}/`,
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
    setDeleteModal(false)
};

const getDamageReportDetailAction = (company, id, dispatch, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.DAMAGE_REPORT + `/${company}/${id}/`,
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(setDamageReportSlice(info))
        onRes(info)
    }
};

//damge
const addDamageAction = (company, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        damage_cause: data.damageCause,
        damage_type: data.damageType,
        description: data.damageDescription,
        damage_report: data?.damage_report,
        images: await Promise.all(data.document.map(async (doc) => ({
            image: await handleFileConversion(doc.document)
        })))
    }

    const res = await api(
        "post",
        endPoints?.DAMAGE + `/${company}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};

const getDamageDetailAction = (company, id, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.DAMAGE + `/${company}/${id}/`,
    );

    if (res?.success) {
        const info = res?.data?.results
        onRes(info)
        // dispatch(setDamageReportSlice(info))
    }
};

const updateDamageAction = (company, damage, data, setLoading, dispatch, onRes) => async () => {
    setLoading(true)
    let payload = {
        damage_cause: data.damageCause,
        damage_type: data.damageType,
        description: data.damageDescription,
        damage_report: data?.damage_report,
        images: await Promise.all(data.document.map(async (doc) => ({
            image: await handleFileConversion(doc.document),
            id: doc?.id
        })))
    }
    const res = await api(
        "put",
        endPoints?.DAMAGE + `/${company}/${damage}/`,
        payload
    );

    if (res?.success) {
        const info = res?.data?.results
        dispatch(showToastAction({
            type: "success",
            detail: res?.message,
        }))
        onRes(info)
    } else {
        dispatch(showToastAction({
            type: "error",
            detail: res?.message,
        }))
    }
    setLoading(false);
};
export {
    //damage report
    addDamageReportAction,
    updateDamageReportAction,
    getDamageReportListAction,
    deleteDamageReportAction,
    getDamageReportDetailAction,

    //damage
    addDamageAction,
    getDamageDetailAction,
    updateDamageAction
};