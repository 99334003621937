import api from "../../services/api";
import endPoints from "../../services/endPoints";

const addOfficeAction = (company, data, loading, navigate, onError) => async () => {
    loading(true)
    const res = await api(
        "post",
        endPoints?.ADD_OFFICE + `${company}/`,
        data
    );
    if (res?.success) {
        navigate("/administrator/office/list")
    } else {
        onError(res?.message);
    }
    loading(false)
};

const getOfficeListAction = (company, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_LIST + `${company}/`,
    );
    onRes(res);
};

const officeListAction = (company, rows, page, search, ordering, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_LIST + `${company}/?limit=${rows}&page=${page}&search=${search}&ordering=${ordering}`,
    );
    onRes(res);
};

const getOfficeInfoAction = (company, officeId, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.OFFICE_LIST + `${company}/${officeId}/`,
    );
    onRes(res)
};

const updateOfficeAction = (company, officeId, data, loading, navigate, onError) => async () => {
    loading(true)
    const res = await api(
        "put",
        endPoints?.ADD_OFFICE + `${company}/${officeId}/`,
        data
    );
    if (res?.success) {
        navigate(`/administrator/office/view/${officeId}`)
    } else {
        onError(res?.message);
    }
    loading(false)
};


const officeDeleteAction = (company, officeId, loading, deleteModal, onRes) => async () => {
    loading(true)
    const res = await api(
        "delete",
        endPoints?.OFFICE_LIST + `${company}/${officeId}`,
    );
    onRes(res);
    loading(false)
    deleteModal(false)
};

export {
    addOfficeAction,
    getOfficeListAction,
    getOfficeInfoAction,
    updateOfficeAction,
    officeDeleteAction,
    officeListAction,
    // searchOfficeAction
};
