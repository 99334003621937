import React from "react";
import Page from "../../../../../shared/Page";
import CustomCard from "../../../../../shared/CustomCard";
import { CustomDropDown, CustomForm } from "../../../../../shared/AllInputs";
import CustomButton, {
  Buttonlayout,
  CustomButtonOutlined,
} from "../../../../../shared/CustomButton";
import DamageContainer from "./DamageContainer";
import { CustomViewInput } from "../../../../../shared/AllViewInputs";
import { TabPanel, TabView } from "primereact/tabview";
import PropertyTableModal from "./PropertyTableModal";
import AddDamageFrom from "../../../../CommonComponents/AddDamageForm";
import CustomDamageTable from "../../../../Tables/CustomDamageTable";
import { damageReportType } from "../../../../../shared/AllDropDownOptions";
import DamageCommentTable from "../../../../Tables/DamageCommentTable";

const DamageForms = ({ type }) => {
  const {
    propertyData,
    setPropertyData,
    onSubmit,
    loading,
    navigate,
    isPropertyModal,
    setIsPropertyModal,
    onConfirm,
    selectedProperty,
    setSelectedProperty,
    isAddDamage,
    setIsAddDamage,
    allDamages,
    setAllDamages,
    // handleDeleteDamage,
    handleEditDamage,
    // deleteDamageModal,
    // setDeleteDamageModal,
    damagesheader,
    reportId
  } = DamageContainer();

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        {type === "Add" && (
          <CustomButton
            type="button"
            size="small"
            label="Select Property"
            onClick={() => setIsPropertyModal(true)}
          />
        )}
      </div>
    );
  };

  return (
    <>
      {isAddDamage ? (
        <>
          <AddDamageFrom
            reportId={reportId}
            allDamages={allDamages}
            setAllDamages={setAllDamages}
            setIsAddDamage={setIsAddDamage}
          />
        </>
      ) : (
        <>
          <Page
            title={`Report Damage`}
            description={`Let’s ${type} your Damage Details`}
            navigateLink={"/real-estate/maintenance/damage/list"}
          >
            <CustomCard>
              <CustomForm title={`Genral`} header={header()}>
                <CustomViewInput data={propertyData} name="propertyID" />
                <CustomViewInput data={propertyData} name="landlordName" />
                <CustomViewInput data={propertyData} name="tenantsName" />
                <CustomViewInput data={propertyData} name="address" />
                {type !== "Add" && (
                  <CustomDropDown
                    data={propertyData}
                    onChange={({ value }) => {
                      setPropertyData({ ...propertyData, status: value });
                    }}
                    name="status"
                    options={damageReportType}
                    required
                  />
                )}
              </CustomForm>
              <TabView className="tabs-design">
                <TabPanel header="Damages">
                  <CustomDamageTable
                    // loading={loading}
                    allDamages={allDamages}
                    // setAllDamages={setAllDamages}
                    header={damagesheader()}
                    // onDelete={handleDeleteDamage}
                    onEdit={handleEditDamage}
                    // deleteModal={deleteDamageModal}
                    // setDeleteModal={setDeleteDamageModal}
                  />
                </TabPanel>
                <TabPanel header="Comments">
                  <DamageCommentTable />
                </TabPanel>
              </TabView>
            </CustomCard>
            <Buttonlayout>
              <CustomButton onClick={onSubmit} label="Save" loading={loading} />
              <CustomButtonOutlined
                label="Cancel"
                onClick={() => {
                  navigate("/real-estate/maintenance/damage/list");
                }}
              />
            </Buttonlayout>
          </Page>
        </>
      )}

      {isPropertyModal && (
        <PropertyTableModal
          isOpen={isPropertyModal}
          setIsOpen={setIsPropertyModal}
          heading={"Select Property"}
          submitLabel={"Confirm"}
          onSubmit={onConfirm}
          loading={loading}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
        />
      )}
    </>
  );
};

export default DamageForms;
