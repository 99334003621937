import React from "react";
import { CustomForm } from "../../shared/AllInputs";

const DamageCommentTable = () => {

    return(
        <CustomForm title={"Comments"}>DamageCommentTable</CustomForm>
    )
}

export default DamageCommentTable