import React, { useRef } from "react";
import { CustomForm } from "../../shared/AllInputs";
import CommonViewTable from "./CommonViewTable";
import { Accordion, AccordionTab } from "primereact/accordion";
import { OverlayPanel } from "primereact/overlaypanel";
import { CustomImageView } from "../../shared/AllViewInputs";
// import CustomModal from "../../shared/Modals/CustomModal";
// import { useDispatch } from "react-redux";
// import { handleDeleteNew } from "../../shared/Components/commonFunctions";
// import { deleteDamageAction } from "../../store/actions/damageActions";

const CustomDamageTable = ({
  // loading,
  allDamages,
  // setAllDamages,
  header,
  // onDelete,
  onEdit,
  // deleteModal,
  // setDeleteModal,
}) => {
  // const dispatch = useDispatch()
  const headerOp = useRef(null);
  // const [deleteModal, setDeleteModal] = useState(false);
  // const [idToDelete, setIdToDelete] = useState({ id: "", index: "" });
  // const [loading, setLoading] = useState(false);

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected damage from the list? This
        action cannot be undone.
      </span>
    );
  };

  const accordianHeader = (damage, index) => {
    return (
      <div className="flex align-items-center justify-content-between w-full ">
        <p className="title">Damage {index + 1}</p>
        <i
          className="pi pi-ellipsis-v cursor-pointer text-dark"
          onClick={(e) => headerOp.current.toggle(e)}
        ></i>
        <OverlayPanel ref={headerOp} className="px-3">
          <ul className="no-style p-0">
            {damage?.id && (
              <li className="flex gap-2 text-xs font-medium mb-3">
                <i
                  className="pi pi-file-edit cursor-pointer text-dark"
                  onClick={() => onEdit(damage?.id, index)}
                ></i>{" "}
                Edit
              </li>
            )}
            {/* <li className="flex gap-2 text-xs font-medium mb-3">
              <i
                className="pi pi-trash cursor-pointer text-dark"
                onClick={() => {
                  setIdToDelete({ id: damage?.id, index: index });
                  setDeleteModal(true);
                }}
              ></i>{" "}
              Delete
            </li> */}
          </ul>
        </OverlayPanel>
      </div>
    );
  };

  // const handleDeleteDamage = () => {
  //   if (idToDelete?.id) {
  //     setLoading(true);
  //     dispatch(
  //       deleteDamageAction(idToDelete?.id, setLoading, dispatch, (res) => {
  //         handleDeleteNew(
  //           allDamages,
  //           setAllDamages,
  //           idToDelete?.index
  //         );
  //         setIdToDelete({ id: "", index: "" });
  //         setDeleteModal(false);
  //       })
  //     );
  //   } else {
  //     handleDeleteNew(allDamages, setAllDamages, idToDelete?.index);
  //     setIdToDelete({ id: "", index: "" });
  //     setDeleteModal(false);
  //   }
  // };

  return (
    <>
      <CustomForm title=" " header={header}>
        {allDamages?.length ? (
          allDamages?.map((damage, index) => {
            return (
              <Accordion activeIndex={damage.id} className="w-full">
                <AccordionTab
                  headerClassName="accordion-role-list"
                  header={() => accordianHeader(damage, index)}
                >
                  <div className="p-4 w-full bg-primary-light border-round-xl grid mt-3">
                    <div className="col-6">
                      <h4 className="mb-2 capitalize">
                        {damage?.service?.name}
                      </h4>
                      <p className="mb-2">Damage Cause</p>
                      <span className="font-bold">{damage?.damageCause}</span>
                    </div>
                    <div className="col-6">
                      <p className="mb-2">Damage Type</p>
                      <span className="font-bold">{damage?.damageType}</span>
                    </div>
                    <p className="col-12">
                      <span className="title">Damage Description </span>{" "}
                    </p>
                    <span>{damage?.damageDescription}</span>
                    <p className="col-12">
                      <span className="title">Damage Photos </span>{" "}
                    </p>
                    <div className="flex gap-4 flex-wrap">
                      {damage?.document?.map((image) => {
                        if (image.document) {
                          return <CustomImageView src={image?.document} />;
                        } else {
                          return <CustomImageView image={`${image}`} />;
                        }
                      })}
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            );
          })
        ) : (
          <CommonViewTable title={""} list={[]} />
        )}
      </CustomForm>
      {/* <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        // onSubmit={() => onDelete(idToDelete?.id, idToDelete?.index)}
        loading={loading}
      /> */}
    </>
  );
};

export default CustomDamageTable;
