import api from "../../services/api";
import endPoints from "../../services/endPoints";

const addEmployeeAction = (company, data, loading, navigate, onError) => async () => {
    loading(true)
    const res = await api(
        "post",
        endPoints?.EMPLOYEE_LIST + `${company}/`,
        data
    );
    if (res?.success) {
        navigate("/administrator/employees/list")
    } else {
        onError(res?.message);
    }
    loading(false)
};

const getEmployeesListAction = (company, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.EMPLOYEE_LIST + `${company}/`,
    );
    onRes(res);
};

const employeesListAction = (company, rows, page, search, ordering, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.EMPLOYEE_LIST + `${company}/?limit=${rows}&page=${page}&search=${search}&ordering=${ordering}`,
    );
    onRes(res);
};

const getEmployeeInfoAction = (company, empId, onRes) => async () => {
    const res = await api(
        "get",
        endPoints?.EMPLOYEE_LIST + `${company}/${empId}`,
    );
    onRes(res);
};

const updateEmployeeAction = (company, id, data, loading, navigate, onError) => async () => {
    loading(true)
    const res = await api(
        "put",
        endPoints?.EMPLOYEE_LIST + `${company}/${id}/`,
        data
    );
    if (res?.success) {
        navigate(`/administrator/employees/view/${id}`)
    } else {
        onError(res?.message);
    }
    loading(false)
};

const employeeDeleteAction = (company, empId, loading, deleteModal, onRes) => async () => {
    loading(true)
    const res = await api(
        "delete",
        endPoints?.EMPLOYEE_LIST + `${company}/${empId}`,
    );
    onRes(res);
    loading(false)
    deleteModal(false)
};

const searchEmployeeIdAction = (payload, onRes) => async () => {
    const res = await api(
        "post",
        endPoints?.SEARCH_EMPLOYEE_ID,
        payload
    )
    onRes(res)
}

export {
    addEmployeeAction,
    getEmployeesListAction,
    getEmployeeInfoAction,
    updateEmployeeAction,
    employeeDeleteAction,
    employeesListAction,
    searchEmployeeIdAction
};
