import React from "react";
import { CustomForm } from "../../shared/AllInputs";
import { CustomViewInput } from "../../shared/AllViewInputs";
import { useNavigate, useParams } from "react-router-dom";
import EditIcon from "../../assets/images/icons/editicon.png";
// import CommonViewTable from "../Tables/CommonViewTable";
// import CustomBankAccountTable from "../Tables/CustomBankAccountTable";
// import CustomLandlordFurnitureTable from "../Tables/CustomLandlordFurnitureTable";
// import CustomLandlordContactTable from "../Tables/CustomLandlordContactTable";

const ViewLandlordInfo = ({
  type,
  data,
  // allFurniture,
  // setAllFurniture,
  // allBankAccounts,
  // setAllBankAccounts,
  // allContacts,
  // setAllContacts,
}) => {
  const params = useParams();
  const navigate = useNavigate();
  // const bankColumns = useMemo(
  //   () => [
  //     {
  //       name: "Purpose",
  //       accessor: "purpose",
  //     },
  //     {
  //       name: "Owner Name",
  //       accessor: "owner_name",
  //     },
  //     {
  //       name: "IBAN",
  //       accessor: "iban",
  //     },
  //     {
  //       name: "BIC",
  //       accessor: "bic",
  //     },
  //   ],
  //   []
  // );

  // const actions = () => {
  //   return <i className="pi pi-ellipsis-v text-gray-400"></i>;
  // };

  //   () => [
  //     {
  //       name: "Type",
  //       accessor: "type",
  //     },
  //     {
  //       name: "Name",
  //       accessor: "name",
  //     },
  //     {
  //       name: "Role",
  //       accessor: "role",
  //     },
  //     {
  //       name: "Mobile",
  //       accessor: "mobile",
  //     },
  //     {
  //       name: "Office Phone",
  //       accessor: "office_phone",
  //     },
  //     {
  //       name: "Email",
  //       accessor: "email",
  //     },
  //   ],
  //   []
  // );

  // const furnitureColumns = useMemo(
  //   () => [
  //     {
  //       name: "Type",
  //       accessor: "type",
  //     },
  //     {
  //       name: "Item",
  //       accessor: "item",
  //     },
  //   ],
  //   []
  // );

  const header = () => {
    return (
      <div className="flex justify-content-around cursor-pointer">
        <img
          src={EditIcon}
          alt=""
          onClick={() =>
            type === "property"? navigate(`/real-estate/property/edit/landlord-info/${params?.id}`) : navigate(`/real-estate/landlord/edit/${params?.id}`)
          }
        ></img>
      </div>
    );
  };

  return (
    <>
      <CustomForm title="Landlord info" header={header()}>
        <CustomViewInput
          name="existingLandlord"
          value={data?.existingLandlord || data?.landlordId}
          label={"Landlord ID"}
        />
        <CustomViewInput name="landlordName" data={data} />
        <CustomViewInput name="landlordType" data={data} label={"Type"} />
        <CustomViewInput name="address" data={data} />
        <CustomViewInput name="street" data={data} />
        <CustomViewInput name="zipCode" data={data} />
        <CustomViewInput name="city" data={data} />
        <CustomViewInput
          name="cityDivision"
          data={data}
        />
        <CustomViewInput name="country" data={data} />
        <CustomViewInput name="email" data={data} />
        <CustomViewInput name="phoneNumber" data={data} label={"Contact"} />
      </CustomForm>
      {/* <CustomForm title="Banking Details">
        <CustomViewInput name="directDebit" data={data} />
      </CustomForm> */}
      {/* <CommonViewTable
        title={"Bank Accounts"}
        list={allBankAccounts}
        columns={bankColumns}
        actions={actions}
      /> */}
      {/* <CustomBankAccountTable
        action={type}
        id={data?.id}
        title="Bank Accounts"
        allBankAccounts={allBankAccounts}
        setAllBankAccounts={setAllBankAccounts}
      /> */}
      {/* <CustomForm title="Landlord Furniture">
        <CustomViewInput
          name="furnitureProvided"
          data={data}
          label="Furniture provide by the landlord"
        />
      </CustomForm>
      <CustomLandlordFurnitureTable
        action={type}
        id={data?.id}
        title={"Furniture"}
        allFurniture={allFurniture}
        setAllFurniture={setAllFurniture}
      /> */}
      {/* <CommonViewTable
        title={"Furniture"}
        list={allFurniture}
        columns={furnitureColumns}
        actions={actions}
      /> */}
      {/* <CommonViewTable
        title={"Contacts"}
        list={allContacts}
        columns={contactColumns}
        actions={actions}
      /> */}
      {/* <CustomLandlordContactTable
        action={type}
        id={data?.id}
        title={"Contacts"}
        allContacts={allContacts}
        setAllContacts={setAllContacts}
      /> */}
    </>
  );
};

export default ViewLandlordInfo;
