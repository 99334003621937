import React, { useEffect, useRef, useState } from "react";
import CustomCard from "../../../shared/CustomCard";
import { CustomSearch } from "../../../shared/AllInputs";
import CustomButton from "../../../shared/CustomButton";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import { getUserInfo } from "../../../services/auth";
import {
  employeeDeleteAction,
  getEmployeesListAction,
} from "../../../store/actions/EmployeeActions";
import { useDispatch, useSelector } from "react-redux";
import { OverlayPanel } from "primereact/overlaypanel";
import CustomOverlay from "../../../shared/CustomOverlay";
import { Messages } from "primereact/messages";
import CustomModal from "../../../shared/Modals/CustomModal";
import { Toast } from "primereact/toast";

const AnnualStatementList = () => {
  const dispatch = useDispatch();
  const [selectedProducts, setSelectedProducts] = useState(null);
  const [rowClick, setRowClick] = useState(true);
  const navigate = useNavigate();
  const { profile } = useSelector((state) => state.user);
  const [employeesList, setEmployeesList] = useState([]);
  const [userProfile, setUserProfile] = useState();
  const msgs = useRef(null);
  const [deleteModal, setDeleteModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");

  useEffect(() => {
    let info = "";
    const userInfo = getUserInfo();
    profile?.firstName ? (info = profile) : (info = userInfo);
    setUserProfile(info);
    // getEmployeesList(info?.company);
  }, [profile]);

  // const getEmployeesList = (company) => {
  //   dispatch(
  //     getEmployeesListAction(company, (res) => {
  //       if (res?.success) {
  //         let options = res?.data.results.map((employee) => {
  //           const date = new Date(employee?.user?.created_at);
  //           let activeFrom = date.toLocaleDateString();
  //           return {
  //             id: employee?.id,
  //             employeeId: employee?.employee_id,
  //             name: `${employee?.user?.first_name} ${employee?.user?.last_name}`,
  //             email: employee?.user?.email,
  //             phoneNumber: employee?.user?.phone_number,
  //             role: employee?.role?.name,
  //             position: "position",
  //             activeFrom: activeFrom,
  //           };
  //         });
  //         setEmployeesList(options);
  //       }
  //     })
  //   );
  // };

  const header = () => {
    return (
      <div className="w-12 md:w-7 flex justify-content-end flex-wrap">
        {/* <div className="w-12 md:w-8 mr-3">
          <CustomSearch />
        </div> */}
        <CustomButton
          size="small"
          icon="pi pi-plus"
          label="Add Annual Statement"
          //   onClick={() => navigate("/administrator/employees/add")}
          disabled
        />
      </div>
    );
  };

  const actions = (d) => {
    return (
      <CustomOverlay>
        <ul className="no-style p-0">
          <li
            className="flex gap-2 text-xs font-medium mb-3 cursor-pointer"
            onClick={() => navigate(`/administrator/employees/view/${d.id}`)}
          >
            <i className="pi pi-eye cursor-pointer text-dark"></i>
            View
          </li>
          <li className="flex gap-2 text-xs font-medium mb-3">
            <i
              className="pi pi-trash cursor-pointer text-dark"
              onClick={() => {
                setIdToDelete(d?.id);
                setDeleteModal(true);
              }}
            ></i>{" "}
            Delete
          </li>
        </ul>
      </CustomOverlay>
    );
  };

  // const handleEmployeeDelete = () => {
  //   dispatch(
  //     employeeDeleteAction(
  //       userProfile?.company,
  //       idToDelete,
  //       setLoading,
  //       setDeleteModal,
  //       (res) => {
  //         if (res?.success) {
  //           msgs?.current?.show({
  //             severity: "success",
  //             detail: res?.message,
  //           });
  //           getEmployeesList(userProfile?.company);
  //         } else {
  //           msgs?.current?.show({
  //             severity: "error",
  //             detail: res?.message,
  //           });
  //         }
  //       }
  //     )
  //   );
  // };

  const modalContent = () => {
    return (
      <span>
        Are you sure you want to delete the selected employee from the list?
        This action cannot be undone.
      </span>
    );
  };

  return (
    <div>
      <CustomCard title="Annual Statement" header={header()}>
        {/* <Messages className="w-full" ref={msgs} /> */}
        <Toast ref={msgs} />
        <DataTable
          className="mt-4"
          value={employeesList}
          tableStyle={{ minWidth: "50rem" }}
          selectionMode={rowClick ? null : "checkbox"}
          selection={selectedProducts}
          onSelectionChange={(e) => setSelectedProducts(e.value)}
          dataKey="id"
          paginator
          rows={5}
          rowsPerPageOptions={[5, 10, 25, 50]}
        >
          <Column
            headerClassName="custom-header"
            selectionMode="multiple"
            headerStyle={{ width: "3rem" }}
          ></Column>
          <Column
            headerClassName="custom-header"
            field="employeeId"
            header="Year"
          ></Column>
          <Column
            headerClassName="custom-header"
            field="name"
            header="In-status"
          ></Column>
          <Column
            headerClassName="custom-header"
            field="email"
            header="Out-status"
          ></Column>
          <Column
            headerClassName="custom-header"
            field="phoneNumber"
            header="Printing date"
          ></Column>
          <Column
            headerClassName="custom-header"
            field="role"
            header="Property ID"
          ></Column>
          <Column
            headerClassName="custom-header"
            body={actions}
            style={{ width: "80px" }}
            header="Action"
          ></Column>
        </DataTable>
      </CustomCard>
      <CustomModal
        isOpen={deleteModal}
        setIsOpen={setDeleteModal}
        heading={"Delete"}
        body={modalContent}
        submitLabel={"Confirm"}
        // onSubmit={handleEmployeeDelete}
        loading={loading}
      />
    </div>
  );
};

export default AnnualStatementList;
