import React, { useEffect, useMemo, useRef, useState } from "react";
import formValidation from "../../../../utils/validations";
import { checkIsValueGreater, handleFileConversion, handleFormatDate, handleSetAddress, showFormErrors } from "../../../../utils/commonFunctions";
import { useDispatch, useSelector } from "react-redux";
import { getUserInfo } from "../../../../services/auth";
import { useLocation, useNavigate } from "react-router-dom";
import {
    addPropertyAction,
    // getPropertyDataAction, 
    searchPropertyIdAction,
    updatePropertyAction
} from "../../../../store/actions/propertyActions";
import { addBuildingAction, searchBuildingAction } from "../../../../store/actions/buildingActions";
import { debounce } from "lodash";
import { addBuildingUnitAction, updateBuildingUnitAction } from "../../../../store/actions/buildingUnitActions";
import { addLandlordAction, searchLandlordAction, updateLandlordAction } from "../../../../store/actions/landlordActions";
import { addContractAction, addRoomAndCostAction, searchContractAction, updateContractAction } from "../../../../store/actions/contractActions";
import {
    checkBankErrors,
    checkContactErrors,
    checkEnergyMeterErrors,
    checkExtraCostErrors,
    checkFurnitureErrors,
    checkOptionalMaturtiesErrors,
    checkRoomErrors,
    // handleBankSave, 
    // handleContactSave,
    // handleCostSave, 
    // handleEnergyMeterSave, 
    // handleFurnitureSave, 
    // handleMaturitiesSave 
} from "../../../../shared/Components/commonFunctions";
import {
    handleSetBuildingOptions,
    handleSetBuildingSturctureData,
    handleSetBuildingUnitData,
    handleSetContractData,
    handleSetLandlordData,
    handleSetPropertyData,
    handleSetRentData,
    // handleSetRentData 
} from "../PropertyCommonFunctions";
import { setPropertyContractSlice, setPropertySlice } from "../../../../store/slices/propertySlice";

const PropertyContainer = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate();
    const [msgToShow, setMsgToShow] = useState({ type: "", message: "" });
    const { profile } = useSelector((state) => state.user);
    const [userProfile, setUserProfile] = useState();
    const userProfileRef = useRef(userProfile);
    const [loading, setLoading] = useState(false);
    const skipAble = [1]
    const [activeIndex, setActiveIndex] = useState(0)
    const [disableTabs, setDisableTabs] = useState([1, 2, 3, 4, 5])
    // const [disableTabs, setDisableTabs] = useState([])
    const [propertyId, setPropertyId] = useState("")
    const routes = [
        { index: 0, route: "building-structure" },
        { index: 1, route: "building-unit" },
        { index: 2, route: "landlord-info" },
        { index: 3, route: "contractual-info" },
        { index: 4, route: "rent-and-cost" },
        { index: 5, route: "annual-statement" },
    ]
    const [tabToActive, setTabToActive] = useState("");

    useEffect(() => {
        let info = "";
        const userInfo = getUserInfo();
        profile?.firstName ? (info = profile) : (info = userInfo);
        setUserProfile(info);
    }, [profile]);

    useEffect(() => {
        userProfileRef.current = userProfile; // Update the ref with the latest userProfile
    }, [userProfile]);

    useEffect(() => {
        let path = location?.pathname.split("/")
        let lastSegment = path[path.length - 1];
        routes?.map((item) => {
            if (item.route === lastSegment && activeIndex !== item?.index) {
                setActiveIndex(item?.index)
            }
        })
    }, [location?.pathname])

    useEffect(() => {
        if (activeIndex !== 0 && !propertyId) {
            navigate("/dashboard")
        }
    }, [activeIndex, propertyId])

    const handleTabChange = (index) => {
        routes?.map((item) => {
            if (item?.index === index) {
                navigate(`/real-estate/property/add/${item?.route}`)
                setActiveIndex(item?.index)
            }
        })

        // if (propertyId) {
        //     getPropertyData()
        // }

        const updatedTabs = disableTabs.filter(tab => tab !== index)
        setDisableTabs(updatedTabs)
    }

    //property from
    const [propertyIdOptions, setPropertyIdOptions] = useState([])
    const [selectedAddress, setSelectedAddress] = useState()
    const [data, setData] = useState({
        propertyId: "",
        address: "",
        zipCode: "",
        city: "",
        houseNo: "",
        street: "",
        cityDivision: "",
        country: "",
    });
    const [buildingOptions, setBuildingOptions] = useState([{ name: "Add New", value: "add_new" }])
    const [buildingStuctureData, setBuildingStuctureData] = useState({
        building: "",
        buildingType: "",
        buildingMaxFloor: "",
        staircaseAccess: "",
        size: "",
        longitude: "",
        latitude: "",
        address: "",
        document: [],
    });
    const [fileKey, setFileKey] = useState(0)
    const [sturcureDocuments, setStructureDocuments] = useState([])

    const handleStructureFileChange = async (event) => {
        const file = event.target.files[0];
        setStructureDocuments([...sturcureDocuments, file]);

        const convertedFile = await handleFileConversion(file)
        let douments = [...buildingStuctureData.document]
        douments.push({ "document": convertedFile })
        setBuildingStuctureData((prev) => ({ ...prev, ["document"]: douments }))
    }

    const handleStructureFileDelete = (index) => {
        let arr = [...sturcureDocuments];
        arr?.splice(index, 1);
        setStructureDocuments(arr);

        let douments = [...buildingStuctureData.document]
        douments?.splice(index, 1);
        setBuildingStuctureData((prev) => ({ ...prev, ["document"]: douments }))

        if (sturcureDocuments.length == 1) {
            setStructureDocuments([]);
            setFileKey((prevKey) => prevKey + 1);
        }
    }

    const handleBuildingSearch = (company, lng, lat) => {
        let buildingOptions = [{ name: "Add New", value: "add_new" }]

        dispatch(
            searchBuildingAction(company, lng, lat, (res) => {
                if (res?.success) {
                    const info = res?.data?.results
                    info?.map((item) => {
                        buildingOptions.push({
                            ...item,
                            name: item?.name,
                            value: item?.id
                        })

                    })
                }
                setBuildingOptions(buildingOptions)

            })
        );
    };

    const buildingIdChange = useRef(debounce(handleBuildingSearch, 1000));

    useMemo(() => {
        if (selectedAddress) {
            handleSetAddress(selectedAddress, data, setData)
            if (selectedAddress?.lng && selectedAddress?.lat) {
                buildingIdChange.current(userProfile?.company, selectedAddress?.lng, selectedAddress?.lat);
                // setBuildingStuctureData(prevState => ({
                //     ...prevState,
                //     id: ""
                // }));
            }
        }

    }, [selectedAddress])

    const handleChange = ({ name, value }) => {
        // if (name === "propertyId" && value) {
        //     propertyIdChange.current(value);
        // }
        const formErrors = formValidation(name, value, data);
        setData((prev) => ({ ...prev, [name]: value, formErrors }));

        if (name === "address") {
            setSelectedAddress("")
        }

    };

    const handlePropertySuggestions = (value) => {
        let payload = {
            property_id: value,
        };

        dispatch(
            searchPropertyIdAction(payload, (res) => {
                if (!res?.success) {
                    const formErrors = ({ ["propertyId"]: res?.message })
                    setData((prev) => ({ ...prev, ["propertyId"]: value, formErrors }));
                    if (res?.data?.results) {
                        setPropertyIdOptions(res?.data?.results)
                    }
                } else {
                    setPropertyIdOptions([])
                }
            })
        );
    };

    const propertyIdChange = useRef(debounce(handlePropertySuggestions, 1000));

    const handleAddProperty = (buildingId) => {

        if (propertyId) {
            dispatch(
                updatePropertyAction(
                    userProfile?.company,
                    propertyId,
                    data,
                    setLoading,
                    dispatch,
                    (res) => {
                        setPropertyId(res?.id)
                        handleSetPropertyData(res, setData)
                        handleTabChange(activeIndex + 1)
                    }
                )
            );
        } else {
            dispatch(
                addPropertyAction(
                    userProfile?.company,
                    data,
                    buildingId,
                    setLoading,
                    dispatch,
                    (res) => {
                        handleTabChange(activeIndex + 1)
                        setPropertyId(res?.id)
                        handleSetPropertyData(res, setData)
                    })
            );
        }
    }

    //building structure
    const BuildingStuctureHandleChange = ({ name, value, ...rest }) => {
        const { files, type } = rest?.target
        const formErrors = formValidation(name, value, buildingStuctureData);

        if (type === "file") {
            setBuildingStuctureData((prev) => ({ ...prev, [name]: files[0], formErrors }))
        } else if (name === "building") {
            const selectedBuilding = buildingOptions.find((item) => item?.id === value)
            handleSetBuildingSturctureData(value, selectedBuilding, setBuildingStuctureData, setStructureDocuments)
        } else {
            setBuildingStuctureData((prev) => ({ ...prev, [name]: value, formErrors }));
        }
    };

    const BuildingStuctureSubmit = async () => {
        if (showFormErrors(buildingStuctureData, setBuildingStuctureData, ["document", "longitude", "latitude", "address"]) && (showFormErrors(data, setData, ["id"]))) {
            let data = {
                ...buildingStuctureData,
                longitude: selectedAddress?.lng,
                latitude: selectedAddress?.lat,
                address: selectedAddress?.address
            }
            dispatch(
                addBuildingAction(
                    userProfile?.company,
                    data,
                    setLoading,
                    dispatch,
                    (res) => {
                        handleAddProperty(res?.id)
                        handleSetBuildingSturctureData(res?.id, res, setBuildingStuctureData, setStructureDocuments)
                        handleSetBuildingOptions(res, buildingOptions, setBuildingOptions)
                        dispatch(setPropertySlice(res))

                    }
                )
            )
        }
    }

    //rooms
    const [allRooms, setAllRooms] = useState([
        { id: null, doors: 1, size: null, name: null, status: null },
    ]);
    // const [roomErrors, setRoomErrors] = useState([]);

    //energy meters
    const [allEnergyMeters, setAllEnergyMeters] = useState(
        [{ id: null, purpose: "", type: "", meter_id: "", location: "", access: "", amount: "", status: "" }]
    )
    // const [meterErrors, setMeterErrors] = useState([]);

    //building unit
    const [buildingUnitData, setBuildingUnitdata] = useState({
        id: "",
        // name: "",
        unitType: "Residential",
        location: "",
        floor: "",
        waterHeating: "",
        size: "",
        heatingTechnology: "",
    })

    const BuildingUnitChange = ({ name, value, }) => {
        let formErrors = formValidation(name, value, buildingUnitData, ["id"]);

        if (name === "floor") {
            let isGreater = checkIsValueGreater(value, buildingStuctureData.buildingMaxFloor)
            if (isGreater) {
                formErrors = ({ ["floor"]: "The floor number must be lower than the maximum floor of the building." })
            } else {
                formErrors = formValidation(name, value, buildingUnitData, ["id"]);
            }
        }

        if (name === "size") {
            let isGreater = checkIsValueGreater(value, buildingStuctureData.size)
            if (isGreater) {
                formErrors = ({ ["size"]: "The size must be lower than the maximum size of the building." })
            } else {
                formErrors = formValidation(name, value, buildingUnitData, ["id"]);
            }
        }

        setBuildingUnitdata((prev) => ({ ...prev, [name]: value, formErrors }));

    };

    const BuildingUnitSubmit = () => {
        const rooms = checkRoomErrors(allRooms, setAllRooms, buildingUnitData?.size)
        const energyMeters = checkEnergyMeterErrors(allEnergyMeters, setAllEnergyMeters)
        if (showFormErrors(buildingUnitData, setBuildingUnitdata, ["id"]) && !rooms?.hasErrors && !energyMeters?.hasErrors) {
            let data = {
                ...buildingUnitData,
                propertyId,
                rooms: rooms?.rooms,
                energy_meters: energyMeters?.energyMeters
            }
            setLoading(true)
            if (buildingUnitData.id) {
                dispatch(
                    updateBuildingUnitAction(buildingUnitData?.id, data, setLoading, dispatch, (res) => {
                        // handleTabChange(activeIndex + 1)
                        // const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
                        // isActive ? handleTabChange(tabToActive) : handleTabChange(activeIndex + 1)
                        //     (tabToActive || tabToActive == 0) ? handleTabChange(tabToActive) : handleTabChange(activeIndex + 1)
                        handleSetBuildingUnitData(res, setBuildingUnitdata, setAllRooms, setAllEnergyMeters)
                        handleTabChange(activeIndex + 1)
                    })
                );
            } else {
                dispatch(
                    addBuildingUnitAction(
                        data,
                        setLoading,
                        dispatch,
                        (res) => {
                            // const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
                            // isActive ? handleTabChange(tabToActive) : handleTabChange(activeIndex + 1)
                            handleTabChange(activeIndex + 1)
                            // (tabToActive || tabToActive == 0)? handleTabChange(tabToActive) : handleTabChange(activeIndex + 1)
                            handleSetBuildingUnitData(res, setBuildingUnitdata, setAllRooms, setAllEnergyMeters)
                        }
                    )
                )
            }
        }
    }

    //bankaccounts
    const [allBankAccounts, setAllBankAccounts] = useState([
        { id: null, purpose: "All Combined", owner_name: "", iban: "", bic: "", status: "" },
    ]);
    // const [bankErrors, setBankErrors] = useState([])

    //furniture 
    const [allFurniture, setAllFurniture] = useState([]);
    // const [furnitureErrors, setFurnitureErrors] = useState([])

    //contacts
    const [allContacts, setAllContacts] = useState([{
        type: "",
        name: "",
        role: "",
        mobile: "",
        office_phone: "",
        email: "",
    }])

    // const [contactErrors, setContactErrors] = useState([]);

    //landlord
    const [landlordOptions, setLandlordOptions] = useState([])
    const [landlordAddress, setLandlordAddress] = useState()
    const [landLordData, setLandLordData] = useState({
        id: "",
        existingLandlord: "",
        landlordName: "",
        landlordType: "",
        address: "",
        street: "",
        zipCode: "",
        city: "",
        cityDivision: "",
        country: "",
        email: "",
        phoneNumber: "",
        directDebit: "",
        furnitureProvided: "",
        landlord_furniture: []
    })

    useMemo(() => {
        if (landlordAddress) {
            handleSetAddress(landlordAddress, landLordData, setLandLordData, ["longitude", "latitude"])
        }
    }, [landlordAddress])

    useEffect(() => {
        if (!landLordData?.landlord_furniture.length) {
            if (landLordData?.furnitureProvided === "yes" && allFurniture.length === 0) {
                setAllFurniture([{ id: null, type: "Kitchen", item: "", status: "" }])
            } else if (landLordData?.furnitureProvided === "no") {
                setAllFurniture([])
            }
        }
    }, [landLordData])

    const LandlordChange = ({ name, value }) => {
        const formErrors = formValidation(name, value, landLordData);
        setLandLordData((prev) => ({ ...prev, [name]: value, formErrors }));

        // if (name === "existingLandlord" && value) {
        //     // landlordIdChange.current(value);
        //     setLandLordData(prevState => ({
        //         ...prevState,
        //         id: ""
        //     }));
        // }
    };

    const handleLandlordSearch = (company, value) => {
        dispatch(
            searchLandlordAction(company, value, (res) => {
                setLandlordOptions(res)
            })
        );
    };

    const landlordIdChange = useRef(debounce((value) => {
        handleLandlordSearch(userProfileRef.current?.company, value)
    }, 1000));

    const LandlordSubmit = () => {
        const bankAccounts = checkBankErrors(allBankAccounts, setAllBankAccounts)
        const furniture = checkFurnitureErrors(allFurniture, setAllFurniture)
        const contacts = checkContactErrors(allContacts, setAllContacts, ["office_phone", "email"])
        if (showFormErrors(landLordData, setLandLordData) && !bankAccounts?.hasErrors && !contacts?.hasErrors && !furniture?.hasErrors) {

            let data = {
                ...landLordData,
                propertyId,
                bank_accounts: bankAccounts?.bankAccounts,
                contacts: contacts?.contacts,
                landlord_furniture: furniture?.furniture,
            }
            setLoading(true)
            if (landLordData?.id) {
                dispatch(
                    updateLandlordAction(
                        userProfile?.company,
                        landLordData?.id,
                        data,
                        setLoading,
                        dispatch,
                        (res) => {
                            // (tabToActive || tabToActive == 0)? handleTabChange(tabToActive) : handleTabChange(activeIndex + 1)
                            // const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
                            // isActive ? handleTabChange(tabToActive) : handleTabChange(activeIndex + 1)
                            handleSetLandlordData(res, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture)
                            handleTabChange(activeIndex + 1)
                        }
                    )
                )
            } else {
                dispatch(
                    addLandlordAction(
                        userProfile?.company,
                        data,
                        setLoading,
                        dispatch,
                        (res) => {
                            // (tabToActive || tabToActive == 0) ? handleTabChange(tabToActive) : handleTabChange(activeIndex + 1)
                            // const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
                            // isActive ? handleTabChange(tabToActive) : handleTabChange(activeIndex + 1)
                            handleSetLandlordData(res, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture)
                            handleTabChange(activeIndex + 1)
                        }
                    )
                )
            }
        }
    }

    useEffect(() => {
        if (landLordData?.existingLandlord?.landlord_id) {
            const landlord = landLordData?.existingLandlord
            handleSetLandlordData(landlord, setLandLordData, setAllBankAccounts, setAllContacts, setAllFurniture)
        }
    }, [landLordData?.existingLandlord])

    //contractual
    const [allMaturities, setAllMaturities] = useState([
        { id: "", extension: "", notice_period: "", status: "" }
    ])

    const [maturityErrors, setMaturityErrors] = useState([])
    const [isContactExist, setIsContactExist] = useState("")

    const [ContractOptions, setContractOptions] = useState([])
    const [contractualData, setContractualData] = useState({
        id: "",
        existingContract: "",
        movingDate: "",
        estateCompany: "",
        contractualSize: "",
        contractualNo: 0,
        maturityType: "",
        maturityDate: "",
        maturityExtension: "",
        noticePeriod: "",
        depositType: "",
        amount: ""
    });

    const ContractualChange = ({ name, value, }) => {

        let ignoreInputs = []
        const formErrors = formValidation(name, value, contractualData, ignoreInputs);

        if (name === "maturityType" && (value === "None" || !value)) {
            ignoreInputs.push("maturityDate", "maturityExtension", "noticePeriod")
        }

        if (data?.maturityType === "Optional Renewal") {
            ignoreInputs.push("noticePeriod")
            ignoreInputs.push("maturityExtension")
        }

        if (contractualData.depositType === "None") {
            ignoreInputs.push("amount")
            setContractualData((prev) => ({ ...prev, ["amount"]: null, formErrors }));
        }
        if (name === "maturityType" && value === "Automatic Renewal") {
            setContractualData((prev) => ({ ...prev, ["maturityExtension"]: 12, formErrors }));
        }

        setContractualData((prev) => ({ ...prev, [name]: value, formErrors }));

        // if (name === "existingContract" && value) {
        //     // contractIdChange.current(value);
        //     setContractualData(prevState => ({
        //         ...prevState,
        //         id: ""
        //     }));
        // }

    };

    const handleContractSearch = (company, value) => {
        dispatch(
            searchContractAction(company, value, (res) => {
                if (res?.success) {
                    const info = res?.data?.results
                    if (!info.length) {
                        setIsContactExist(false)
                    } else {
                        setIsContactExist(true)
                    }
                    setContractOptions(info)
                }
            })
        );
    };

    const contractIdChange = useRef(debounce((value) => {
        handleContractSearch(userProfileRef.current?.company, value)
    }, 1000));

    const ContractualSubmit = () => {
        const maturities = checkOptionalMaturtiesErrors(allMaturities, setAllMaturities)
        let ignoreInputs = []
        if (contractualData?.maturityType === "None" || !contractualData?.maturityType && contractualData?.formErrors) {
            ignoreInputs.push("maturityDate", "maturityExtension", "noticePeriod")
            delete contractualData?.formErrors?.maturityDate
            delete contractualData?.formErrors?.maturityExtension
            delete contractualData?.formErrors?.noticePeriod
        }

        if (contractualData?.maturityType === "Optional Renewal") {
            ignoreInputs.push("noticePeriod", "maturityExtension")
            delete contractualData?.formErrors?.noticePeriod
        }

        if (contractualData.depositType === "None") {
            ignoreInputs.push("amount")
        }

        if (showFormErrors(contractualData, setContractualData, ignoreInputs) && !maturities?.hasErrors) {
            setLoading(true)
            let data = {
                ...contractualData,
                landlordId: landLordData?.id,
                property: propertyId,
                optional_maturities: maturities?.optionalMaturities,
            }
            if (contractualData?.id) {
                dispatch(
                    updateContractAction(userProfile?.company, contractualData?.id, data, setLoading, dispatch, (res) => {
                        // const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
                        // isActive ? handleTabChange(tabToActive) : handleTabChange(activeIndex + 1)
                        dispatch(setPropertyContractSlice(res))
                        handleSetContractData(res, setContractualData, setAllMaturities)
                        handleTabChange(activeIndex + 1)
                        // (tabToActive || tabToActive == 0) ? handleTabChange(activeIndex + 1) : handleTabChange(activeIndex + 1)
                    }))
            } else {
                dispatch(
                    addContractAction(userProfile?.company, data, setLoading, dispatch, (res) => {
                        // setContractualData({
                        //     ...contractualData,
                        //     id: info?.id,
                        //     existingContract: info?.contract_id,
                        //     movingDate: info?.moving_in_date ? new Date(info?.moving_in_date) : "",
                        //     estateCompany: info?.estate_company,
                        //     contractualSize: info?.size,
                        //     contractualNo: info?.room,
                        //     maturityType: info?.maturity_type,
                        //     maturityDate: info?.maturity_date ? new Date(info?.maturity_date) : "",
                        //     maturityExtension: info?.maturity_extension,
                        //     noticePeriod: info?.maturity_notice_period,
                        //     depositType: info?.deposit_type,
                        //     amount: info?.deposit_amount
                        // })

                        // if (info?.optional_maturities?.length) {
                        //     let maturities = info?.optional_maturities?.map((maturity) => {
                        //         return { ...maturity, status: "save" }
                        //     })
                        //     setAllMaturities(maturities)
                        // }
                        // const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
                        // isActive ? handleTabChange(tabToActive) : handleTabChange(activeIndex + 1)
                        handleSetContractData(res, setContractualData, setAllMaturities)
                        dispatch(setPropertyContractSlice(res))
                        handleTabChange(activeIndex + 1)
                            // (tabToActive || tabToActive == 0) ? handleTabChange(activeIndex + 1) : handleTabChange(activeIndex + 1)
                    }))
            }
        }

    }

    useEffect(() => {
        if (contractualData?.existingContract?.contract_id) {
            const contract = contractualData.existingContract
            handleSetContractData(contract, setContractualData, setAllMaturities)
        }
    }, [contractualData?.existingContract])

    //legal allowance
    // const [allLegalAllowance, setAllLegalAllowance] = useState([
    //     { id: "", type: "", applicationDate: "", startDate: "", endDate: "", status: "" }
    // ])

    //extra cost
    const [allExtraCost, setAllExtraCost] = useState([{
        id: "",
        type: "",
        payment_type: "",
        amount: "",
        amount_first_month: "",
        amount_last_month: "",
        applicable_tenant_rent: "",
        status: "",
    }])

    const [costErrors, setCostErrors] = useState([])

    //rent and cost
    const [rentAndCostData, setRentAndCostData] = useState({
        amount: "",
        amountFirstMonth: "",
        rentRiseType: "",
        intervalType: "",
        riseInterval: "",
        startDate: "",
        endDate: "",
        riseAmount: "",
        propertyRent: "Size",
    });

    const RentAndCostChange = ({ name, value, }) => {
        let ignoreInputs = []
        if (name === "rentRiseType" && (value === "None" || !value)) {
            ignoreInputs.push("intervalType", "riseInterval", "startDate", "riseAmount", "endDate")
        }
        const formErrors = formValidation(name, value, rentAndCostData, ignoreInputs);
        setRentAndCostData((prev) => ({ ...prev, [name]: value, formErrors }));
    };

    const RentAndCostSubmit = () => {
        const extraCosts = checkExtraCostErrors(allExtraCost, setAllExtraCost)

        let ignoreInputs = []
        // let startDate = handleFormatDate(rentAndCostData?.startDate)
        // let endDate = handleFormatDate(rentAndCostData?.endDate)
        if (rentAndCostData?.rentRiseType === "None" || !rentAndCostData?.rentRiseType && rentAndCostData?.formErrors) {
            ignoreInputs.push("intervalType", "riseInterval", "startDate", "riseAmount", "endDate")
            delete rentAndCostData?.formErrors?.intervalType
            delete rentAndCostData?.formErrors?.riseInterval
            delete rentAndCostData?.formErrors?.startDate
            delete rentAndCostData?.formErrors?.riseAmount
            delete rentAndCostData?.formErrors?.endDate
        }

        if (showFormErrors(rentAndCostData, setRentAndCostData, ignoreInputs) && !extraCosts?.hasErrors) {
            // let payload = {
            //     rent_amount: rentAndCostData?.amount,
            //     rent_amount_first_month: rentAndCostData?.amountFirstMonth,
            //     rent_rise_type: rentAndCostData?.rentRiseType,
            //     property_rent: rentAndCostData?.propertyRent,
            //     // landlord: landLordData?.id,
            //     landlord: 12,
            // }

            // if (rentAndCostData?.rentRiseType !== "None") {
            //     payload.rent_rise_interval = rentAndCostData?.riseInterval
            //     payload.rent_rise_interval_type = rentAndCostData?.intervalType
            //     payload.date_of_first_rise = startDate
            //     payload.date_of_last_rise = endDate
            //     payload.rent_rise_amount = rentAndCostData?.riseAmount
            // }

            // if (!extraCosts?.hasErrors && extraCosts?.extraCosts?.length) {
            //     let extraCost = extraCosts?.extraCosts?.map((cost) => {
            //         if (cost?.applicable_tenant_rent === "yes") {
            //             return { ...cost, applicable_tenant_rent: true };
            //         } else if (cost?.applicable_tenant_rent === "no") {
            //             return { ...cost, applicable_tenant_rent: false };
            //         }
            //     });

            //     payload.extra_costs = extraCost;
            // }

            let data = {
                ...rentAndCostData,
                extra_costs: extraCosts?.extraCosts
            }

            dispatch(
                addRoomAndCostAction(
                    userProfile.company,
                    contractualData?.id,
                    data,
                    setLoading,
                    dispatch,
                    (res) => {
                        // setRentAndCostData({
                        //     ...rentAndCostData,
                        //     amount: info?.rent_amount,
                        //     amountFirstMonth: info?.rent_amount_first_month,
                        //     rentRiseType: info?.rent_rise_type,
                        //     intervalType: info?.rent_rise_interval_type,
                        //     riseInterval: info?.rent_rise_interval,
                        //     startDate: info?.date_of_first_rise ? new Date(info?.date_of_first_rise) : "",
                        //     endDate: info?.date_of_first_rise ? new Date(info?.date_of_first_rise) : "",
                        //     riseAmount: info?.rent_rise_amount,
                        //     propertyRent: info?.property_rent,
                        // });
                        // const isActive = (tabToActive !== null && tabToActive !== undefined) && (typeof tabToActive === 'number' || tabToActive === 0);
                        // isActive ? handleTabChange(tabToActive) : handleTabChange(activeIndex + 1)
                        handleSetRentData(res, setRentAndCostData, setAllExtraCost)
                        handleTabChange(activeIndex + 1)
                            // (tabToActive || tabToActive == 0) ? handleTabChange(activeIndex + 1) : handleTabChange(activeIndex + 1)
                    }))

        }
    }

    const onSubmit = () => {
        if (activeIndex === 0) {
            BuildingStuctureSubmit()
            showFormErrors(data, setData)
        } else if (activeIndex === 1) {
            BuildingUnitSubmit()
        } else if (activeIndex === 2) {
            LandlordSubmit()
        } else if (activeIndex === 3) {
            ContractualSubmit()
        } else if (activeIndex === 4) {
            RentAndCostSubmit()
        } else if (activeIndex === 5) {
            navigate("/real-estate/property/list")
        }
    };

    // const getPropertyData = () => {
    //     dispatch(
    //         getPropertyDataAction(
    //             userProfile?.company,
    //             propertyId,
    //             (res) => {
    //                 const info = res?.data?.results
    //                 if (info) {
    //                     handleSetPropertyData(info, setData)
    //                     const buildingStrucure = info?.building_data
    //                     handleSetBuildingOptions(buildingStrucure, buildingOptions, setBuildingOptions)
    //                     handleSetBuildingSturctureData("", buildingStrucure, setBuildingStuctureData, setStructureDocuments)
    //                 }
    //             }
    //         )
    //     )
    // }

    return {
        //common
        loading,
        msgToShow,
        skipAble,
        activeIndex,
        handleTabChange,
        disableTabs,
        setTabToActive,

        //add property
        data,
        setData,
        handleChange,
        onSubmit,
        propertyIdOptions,
        propertyId,
        setSelectedAddress,
        propertyIdChange,

        //building structure
        buildingStuctureData,
        BuildingStuctureHandleChange,
        buildingOptions,
        fileKey,
        sturcureDocuments,
        handleStructureFileChange,
        handleStructureFileDelete,

        //building unit
        buildingUnitData,
        BuildingUnitChange,

        //rooms
        allRooms,
        setAllRooms,
        // roomErrors,
        // setRoomErrors,

        //energy metters
        allEnergyMeters,
        setAllEnergyMeters,
        // meterErrors,
        // setMeterErrors,

        //landlord
        landlordOptions,
        LandlordChange,
        landLordData,
        setLandlordAddress,
        landlordIdChange,

        //bank accounts
        allBankAccounts,
        setAllBankAccounts,
        // bankErrors,
        // setBankErrors,

        //contacts
        allContacts,
        setAllContacts,
        // contactErrors,
        // setContactErrors,

        //furniture
        allFurniture,
        setAllFurniture,
        // furnitureErrors,
        // setFurnitureErrors,

        //contractual
        ContractOptions,
        contractualData,
        ContractualChange,
        allMaturities,
        setAllMaturities,
        maturityErrors,
        setMaturityErrors,
        contractIdChange,
        isContactExist,

        //legal allowance
        // allLegalAllowance,
        // setAllLegalAllowance,

        //rise and cost
        rentAndCostData,
        RentAndCostChange,
        RentAndCostSubmit,

        //extra cost
        allExtraCost,
        setAllExtraCost,
        costErrors,
        setCostErrors
    }
}
export default PropertyContainer