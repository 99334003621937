import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./slices/userSlice";
import commonSlice from "./slices/commonSlice";
import bankAccountSlice from "./slices/bankAccountSlice";
import propertySlice from "./slices/propertySlice";
import landlordSlice from "./slices/landlordSlice";
import listSlice from "./slices/listSlices";
import contractSlice from "./slices/contractSlice";
import buildingSlice from "./slices/buildingSlices";
import handymanSlice from "./slices/handymanSlice";
import damageSlice from "./slices/damageSlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    common: commonSlice,
    bankAccounts: bankAccountSlice,
    property: propertySlice,
    landlord: landlordSlice,
    listDetail: listSlice,
    contract: contractSlice,
    building: buildingSlice,
    handyman: handymanSlice,
    damage: damageSlice,
  },
});
